import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../ptsConstants";
import {API_ROOT} from "../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsAdminDiscretionaryProjectsApi {
    static SaveProjectEndDate(discretionaryProjectId, projectEndDate) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/projects/${discretionaryProjectId}/extension`,
                RequestTypes.PUT,
                JSON.stringify(projectEndDate),
                API_MESSAGES.ADMIN.PROJECT.saved));
    }

    static DeleteProject(discretionaryProjectId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/projects/${discretionaryProjectId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.ADMIN.PROJECT.deleted));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsAdminDiscretionaryProjectsApi {
    static SaveProjectEndDate() {
        return ApiUtility.mockResolveApiCall("", API_MESSAGES.ADMIN.PROJECT.saved);
    }

    static DeleteProject() {
        return ApiUtility.mockResolveApiCall({}, API_MESSAGES.ADMIN.PROJECT.deleted);
    }
}

const PtsAdminDiscretionaryProjectsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsAdminDiscretionaryProjectsApi : MockPtsAdminDiscretionaryProjectsApi;
export default PtsAdminDiscretionaryProjectsApi;