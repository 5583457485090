import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as stratPlanActions from "../../actions/strategicPlanActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import GridRow from "../../components/common/GridRow";
import GridColumn from "../../components/common/GridColumn";
import SelectField from "../../components/common/inputs/SelectField";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import HtmlDisplayInline from "../../components/htmlDisplay/HtmlDisplayInline";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import * as config from "../../constants/config";
import {STRATEGIC_PLAN} from "../../constants/contexts";
import * as strategicPlanLocations from "../../constants/strategicPlan/strategicPlanLocations";

export class StrategicPlanLandingPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onDateWindowChange = this.onDateWindowChange.bind(this);
		this.onPlanClick = this.onPlanClick.bind(this);
		this.onReportClick = this.onReportClick.bind(this);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("Strategic Plan");

		if (this.props.dateWindows.length === 0) {
			this.props.actions.loadAllDateWindows();
		}

		this.props.actions.loadPlans();
	}

	onDateWindowChange(event) {
		event.preventDefault();

		this.props.actions.changeSelectedDateWindow(event.target.value);
	}

	onPlanClick(event, planDateWindowId) {
		event.preventDefault();

		this.props.history.push(strategicPlanLocations.PLAN.path
			.replace(strategicPlanLocations.PLAN_DATE_WINDOW_ID, planDateWindowId));
	}

	onReportClick(event, dateWindowId) {
		event.preventDefault();

        this.props.history.push(strategicPlanLocations.REPORT.path
			.replace(strategicPlanLocations.DATE_WINDOW_ID, dateWindowId));
	}

	render() {
		if (this.props.dateWindows.length === 0)
			return null;

		let tiles;
		const fiveYearPlan = (<Tile
			key={this.props.selectedDateWindowId}
			header={"Five Year Plan"}
			body={""}
			footer={"Report"}
			id={this.props.selectedDateWindowId}
			onClick={(event) => this.onReportClick(event, this.props.selectedDateWindowId)}/>);

		const filteredPlans = this.props.plans.filter(plan => plan.dateWindowId === this.props.selectedDateWindowId);

		if (filteredPlans.length > 0) {
			tiles = new Array();
			filteredPlans.map(plan => {
				tiles.push(<Tile
					key={plan.planDateWindowId}
					header={plan.name}
					body={""}
					footer={plan.status}
					id={plan.planDateWindowId}
					onClick={(event) => this.onPlanClick(event, plan.planDateWindowId)}/>);
			});
			tiles.push(fiveYearPlan);
		}

		return (
			<section>
				<p>Select a date window to view plans present during a particular year.</p>
				<GridRow rowClass="filterBar" align="bottom">
					<GridColumn>
						<SelectField name="dateWindow"
						             showLabel={true}
						             label="Date Window"
						             onChange={this.onDateWindowChange}
						             options={this.props.dateWindows}
						             value={this.props.selectedDateWindowId}
						             includeDefaultOption={false}/>
					</GridColumn>
				</GridRow>

				{
					tiles &&
					<TileContainer>
						{tiles}
					</TileContainer>
				}

				{
					filteredPlans.length === 0 &&
					<p>There are no plans associated with the selected date window. If you believe this message to be
						associated with an error,<HtmlDisplayInline html={config.supportDetails(STRATEGIC_PLAN)} />.</p>
				}
			</section>
		);
	}
}

StrategicPlanLandingPage.propTypes = {
	actions: PropTypes.object.isRequired,
	plans: PropTypes.array.isRequired,
	dateWindows: PropTypes.array.isRequired,
	selectedDateWindowId: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	let dateWindows = [...state.sharedData.dateWindows];
	if (dateWindows.length > 0) {
		const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows);
		dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
	}

	return {
		plans: state.strategicPlan.plans,
		dateWindows: dateWindows,
		selectedDateWindowId: state.sharedData.selectedDateWindowId,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedACtions = Object.assign(
		{},
		layoutActions,
		stratPlanActions,
		sharedDataActions);

	return {
		actions: bindActionCreators(combinedACtions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StrategicPlanLandingPage);