import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import * as searchCriteriaActions from "../../actions/searchCriteriaActions";
import AccountUtility from "../../api/AccountUtility";
import {
    createListItem,
    isTrimmedStringEmpty,
    setPageTitle
} from "../../components/common/commonUtilities";
import Button from "../../components/common/buttons/Button";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import * as LayoutActions from "../../actions/layoutActions";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    BpieFilterVisibilityRules,
    BpieSearchCriteria
} from "../../components/common/searchCriterias/BpieSearchCriteria";
import {getParamsMultiple} from "../../components/layout/getParams";
import * as config from "../../constants/config";
import {SBPIE_ASSESSMENT_STATUS, SchoolBpieAssessmentSections} from "./bpieConstants";
import {BpieFilter} from "./BpieFilter";
import * as bpieLocations from "./bpieLocations";
import {createSchoolBpie} from "./bpieObjectFactory";
import bpieApi from "./bpieApi";
import sbpieAssessmentApi from "./School/sbpieAssessmentApi";
import {DASHBOARD} from "../../constants/systemLocations";

const BpieSchoolWrapper = ({
                               actions,
                               Component,
                               currentLocation,
                               history,
                               props,
                               schoolBpieId,
                               schoolBpieSearchCriteria,
                               indicatorNumber
                           }) => {
    const [dataChanged, setDataChanged] = useState(false);
    const [schoolBpies, setSchoolBpies] = useState(null);
    const [schoolBpie, setSchoolBpie] = useState(null);
    const [archivedBpies, setArchivedBpies] = useState(null);

    const schoolInfoUrl = !schoolBpieId
        ? bpieLocations.BPIE_LANDING.getUrl()
        : bpieLocations.BPIE_LANDING_WITH_SCHOOL_BPIE.getUrl(schoolBpieId);
    const introUrl = bpieLocations.BPIE_ASSESSMENT_INTRO.getUrl(schoolBpieId);
    const assessmentUrl = bpieLocations.BPIE_ASSESSMENT.getUrl(schoolBpieId, indicatorNumber).replace(bpieLocations.INDICATOR_NUMBER, 1);
    const summaryUrl = bpieLocations.BPIE_ASSESSMENT_SUMMARY.getUrl(schoolBpieId);
    const prioritiesUrl = bpieLocations.BPIE_ASSESSMENT_PRIORITIES.getUrl(schoolBpieId);

    const getScrollConfig = () => {
        const scrollConfig = {
            duration: 300,
            delay: 0,
            smooth: true
        };

        return scrollConfig;
    };

    const handleScroll = (containerId) => {
        const scroller = Scroll.scroller;
        window.setTimeout(() => {
            scroller.scrollTo(containerId, getScrollConfig());
        }, 150);
    };

    const handleScrollToTop = () => {
        const animateScroll = Scroll.animateScroll;
        window.setTimeout(() => {
            animateScroll.scrollToTop(getScrollConfig());
        }, 150);
    }

    const handleSelectSection = (event, section, forceReplace = false) => {
        let url = "";
        switch (section) {
            case SchoolBpieAssessmentSections.SchoolInformation:
                url = schoolInfoUrl;
                break;
            case SchoolBpieAssessmentSections.Intro:
                url = introUrl;
                break;
            case SchoolBpieAssessmentSections.Assessment:
                url = summaryUrl;
                break;
        }

        if (url)
            navButtonClick(event, url, forceReplace);

        if (forceReplace)
            loadSchoolBpies();
    };

    const getSectionFromCurrentLocation = () => {
        switch (currentLocation) {
            case schoolInfoUrl:
                return SchoolBpieAssessmentSections.SchoolInformation;
            case introUrl:
                return SchoolBpieAssessmentSections.Intro;
            case assessmentUrl:
            case summaryUrl:
            case prioritiesUrl:
                return SchoolBpieAssessmentSections.Assessment;
        }

        return "";
    }

    const navButtonClick = (event, location, forceReplace = false) => {
        event.preventDefault();

        if (forceReplace) {
            normalizeUrlAndRedirect(location, true);
            setDataChanged(false);
            return;
        }

        if (location === currentLocation) return;

        if (dataChanged) {
            if (confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page.")) {
                loadSchoolBpies();
            } else
                return;
        }

        setDataChanged(false);
        normalizeUrlAndRedirect(location);

        handleScrollToTop();
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const normalizeUrlAndRedirect = (location, isReplace = false) => {
        const finalSchoolBpieId = getDefaultSchoolBpieId(schoolBpies);
        const path = location
            .replace(bpieLocations.SCHOOL_BPIE_ID, finalSchoolBpieId)
            .replace(bpieLocations.INDICATOR_NUMBER, 1);
        isReplace ? history.replace(path) : history.push(path);
    };

    const initializeSearchCriteria = (updatedSchoolBpieId, currentSchoolBpies) => {
        let criteria = {...schoolBpieSearchCriteria};

        if (schoolBpieSearchCriteria.schoolBpieId === updatedSchoolBpieId && criteria.areRulesCurrent(BpieFilterVisibilityRules.SchoolBpieWrapper))
            return;

        criteria.schoolBpieId = updatedSchoolBpieId;
        criteria.allSchoolBpies = currentSchoolBpies.map(bpie => createListItem(bpie.schoolBpieId, `${bpie.schoolName} (${bpie.msidNumber})`));
        criteria.setVisibilityRules(BpieFilterVisibilityRules.SchoolBpieWrapper);
        actions.saveSearchCriteria(criteria);
    }

    const getDefaultSchoolBpieId = (currentSchoolBpies) => {
        return schoolBpieId ? schoolBpieId
            : !schoolBpieSearchCriteria.schoolBpieId
                ? currentSchoolBpies && currentSchoolBpies.length > 0 ? currentSchoolBpies[0].schoolBpieId : ""
                : schoolBpieSearchCriteria.schoolBpieId;
    };

    const setCurrentSchoolBpie = (currentSchoolBpies, currentArchivedBpies) => {
        let defaultSchoolBpieId = getDefaultSchoolBpieId(currentSchoolBpies);

        let currentSchoolBpie = currentSchoolBpies.find(f => f.schoolBpieId === defaultSchoolBpieId);
        if (!currentSchoolBpie) {
            currentSchoolBpie = findRelatedSchoolBpie(currentSchoolBpies, currentArchivedBpies, defaultSchoolBpieId);
        }

        if (!currentSchoolBpie) {
            if (currentLocation !== bpieLocations.BPIE_LANDING.getUrl())
                normalizeUrlAndRedirect(bpieLocations.BPIE_LANDING.getUrl());
            else
                actions.updatePageTitle(`SBPIE Assessment`);

            return;
        }

        setSchoolBpie(currentSchoolBpie);

        initializeSearchCriteria(currentSchoolBpie.schoolBpieId, currentSchoolBpies);
    }

    const findRelatedSchoolBpie = (currentSchoolBpies, currentArchivedBpies, schoolBpieId) => {
        const archivedSchoolBpie = currentArchivedBpies.find(f => f.schoolBpieId === schoolBpieId);
        if (archivedSchoolBpie) {
            return currentSchoolBpies.find(f => f.districtId === archivedSchoolBpie.districtId
                && f.msidNumber === archivedSchoolBpie.msidNumber);
        }

        return null;
    };

    const onChangeSchoolBpie = (criteria) => {
        const currentSchoolBpie = schoolBpies.find(f => f.schoolBpieId === criteria.schoolBpieId);
        setSchoolBpie(currentSchoolBpie);
        actions.saveSearchCriteria(criteria);
        history.push(bpieLocations.BPIE_LANDING_WITH_SCHOOL_BPIE.getUrl(criteria.schoolBpieId));
    };

    const createSchoolBpies = (bpies) => {
        let schoolBpies = [];
        for (let bpie of bpies)
            schoolBpies.push(createSchoolBpie(bpie));

        return schoolBpies;
    }

    const handleUpdateSchoolBpies = (result) => {
        const currentArchivedBpies = createSchoolBpies(result.archived);
        setArchivedBpies(currentArchivedBpies);

        const currentSchoolBpies = createSchoolBpies(result.current);
        setSchoolBpies(currentSchoolBpies);
        setDataChanged(false);

        setCurrentSchoolBpie(currentSchoolBpies, currentArchivedBpies);
    };

    const loadSchoolBpies = (callback = null) => {
        actions.executeApi(bpieApi.getSchoolBpies)
            .then((result) => {
                handleUpdateSchoolBpies(result)
                if (callback)
                    callback();
            });
    }

    const handleStartBpieThenRedirectToAssessment = (schoolBpieId) => {
        actions.executeApi(bpieApi.startSchoolBpieAssessment, [schoolBpieId])
            .then(() => {
                loadSchoolBpies(() => {
                    handleAssessmentRedirect(schoolBpieId, 1);
                });
            });
    }

    const handleAssessmentRedirect = (schoolBpieId, desiredIndicator) => {
        if (!desiredIndicator)
            handleSummaryRedirect(schoolBpieId);
        else if (!schoolBpie.isConcluded) {
            normalizeUrlAndRedirect(bpieLocations.BPIE_ASSESSMENT.getUrl(schoolBpieId, desiredIndicator));
            handleScrollToTop();
        }
    };

    const loadAssessment = (callback = null) => {
        actions.executeApi(sbpieAssessmentApi.getSbpieAssessment, [schoolBpieId])
            .then((result) => {
                if (callback) callback(result);
            });
    };

    const handleSummaryRedirect = (schoolBpieId) => {
        normalizeUrlAndRedirect(bpieLocations.BPIE_ASSESSMENT_SUMMARY.getUrl(schoolBpieId));
    }

    const handlePrioritiesRedirect = (schoolBpieId) => {
        normalizeUrlAndRedirect(bpieLocations.BPIE_ASSESSMENT_PRIORITIES.getUrl(schoolBpieId));
    }

    const canShowBpieFilter = () => {
        return currentLocation === schoolInfoUrl && schoolBpies?.length > 1;
    };

    useEffect(() => {
        if (!AccountUtility.isBpieSchoolAdmin()) {
            history.push(DASHBOARD.path);
            return;
        }

        loadSchoolBpies();
    }, []);

    useEffect(() => {
        if (!schoolBpie)
            return;

        const headerLeft = `SBPIE Assessment`;
        const headerCenter = getSectionFromCurrentLocation();
        const headerRight = `${schoolBpie.schoolName} (${schoolBpie.schoolDistrict})`;

        actions.updatePageTitle(headerLeft, headerCenter, headerRight);
        setPageTitle(`${headerLeft}${isTrimmedStringEmpty(headerCenter) ? "" : ` - ${headerCenter}`}; ${schoolBpie.schoolName} (${schoolBpie.schoolDistrict})`);
    }, [schoolBpie, currentLocation]);

    if (!schoolBpie || !schoolBpieSearchCriteria.areRulesCurrent(BpieFilterVisibilityRules.SchoolBpieWrapper))
        return (
            <p>No SBPIE Assessment is available at the moment. If you have any questions, <a
                href={`mailto:` + config.supportEmail}>contact us via email</a>.</p>
        );

    return (
        <GridRow
            isPageNav
        >
            <GridColumn large="3">
                <GridRow>
                    <GridColumn
                        isPageNavLinks
                        isStickyLinks
                    >
                        <Button
                            btnClass={currentLocation === schoolInfoUrl ? "is-active" : ""}
                            onClick={(event) => navButtonClick(event, schoolInfoUrl)}
                            label={SchoolBpieAssessmentSections.SchoolInformation}
                            name={schoolInfoUrl}
                        />
                        {
                            schoolBpie.isActive &&
                            <Button
                                btnClass={currentLocation === introUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, introUrl)}
                                label={SchoolBpieAssessmentSections.Intro}
                                name={introUrl}
                            />
                        }
                        {
                            schoolBpie.status >= SBPIE_ASSESSMENT_STATUS.InProgress.id &&
                            <Button
                                btnClass={(currentLocation === assessmentUrl || currentLocation === summaryUrl || currentLocation === prioritiesUrl) ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, summaryUrl)}
                                label={SchoolBpieAssessmentSections.Assessment}
                                name={summaryUrl}
                            />
                        }
                    </GridColumn>
                </GridRow>
            </GridColumn>
            <GridColumn
                isPageNavContent
                large="9"
            >
                {
                    canShowBpieFilter() &&
                    <BpieFilter
                        handleSearchFiltersChange={onChangeSchoolBpie}
                        searchCriteria={schoolBpieSearchCriteria}
                    />
                }
                <Component
                    {...props}
                    actions={actions}
                    archivedBpies={archivedBpies}
                    bpiApi={bpieApi}
                    dataChanged={dataChanged}
                    handleAssessmentRedirect={handleAssessmentRedirect}
                    handleDataChanged={handleDataChanged}
                    handlePrioritiesRedirect={handlePrioritiesRedirect}
                    handleScroll={handleScroll}
                    handleScrollToTop={handleScrollToTop}
                    handleSelectSection={handleSelectSection}
                    handleStartBpieThenRedirectToAssessment={handleStartBpieThenRedirectToAssessment}
                    handleSummaryRedirect={handleSummaryRedirect}
                    indicatorNumber={indicatorNumber}
                    loadAssessment={loadAssessment}
                    loadSchoolBpies={loadSchoolBpies}
                    schoolBpie={schoolBpie}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const currentLocation = props.location.pathname;

    const multipleParams = getParamsMultiple(props.location.pathname, bpieLocations.AllSchoolBpieRoutes);
    const params = !multipleParams ? {} : multipleParams;

    const selectedSchoolBpieId = (params && params.schoolBpieId) ? params.schoolBpieId : null;
    const schoolBpieSearchCriteria = state.searchCriteria.BpieSearchCriteria || new BpieSearchCriteria(selectedSchoolBpieId);

    return {
        ...params,
        Component,
        currentLocation,
        props,
        schoolBpieSearchCriteria
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions, searchCriteriaActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

BpieSchoolWrapper.propTypes = {
    actions: PropTypes.object,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    history: PropTypes.object,
    props: PropTypes.object,
    schoolBpieId: PropTypes.string,
    schoolBpieSearchCriteria: PropTypes.object.isRequired,
    indicatorNumber: PropTypes.string
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BpieSchoolWrapper);