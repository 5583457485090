import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Allow from "../authorization/Allow";
import * as contexts from "../../constants/contexts";
import * as policyEvents from "../../constants/policyEvents";
import {PtsPolicy} from "../authorization/policies/PtsPolicy";
import { UserPolicy } from "../authorization/policies/UserPolicy";
import { SurveyPolicy } from "../authorization/policies/SurveyPolicy";
import { AdminPolicy } from "../authorization/policies/AdminPolicy";
import { SppPolicy } from "../authorization/policies/SppPolicy";
import { ReportsPolicy } from "../authorization/policies/ReportsPolicy";
import { StrategicPlanPolicy } from "../authorization/policies/StrategicPlanPolicy";
import { AdminMenuPolicy } from "../authorization/policies/AdminMenuPolicy";
import { StateComplaintPolicy } from "../authorization/policies/StateComplaintPolicy";
import { DueProcessPolicy } from "../authorization/policies/DueProcessPolicy";
import { MediationPolicy } from "../authorization/policies/MediationPolicy";
import LocalStorage from "../shared/LocalStorage";
import * as strategicPlanLocations from "../../constants/strategicPlan/strategicPlanLocations";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import * as systemLocations from "../../constants/systemLocations";
import * as reportLocations from "../../Reports/reportLocations";
import * as sppLocations from "../../constants/spp/sppLocations";
import * as usersLocations from "../../constants/users/usersLocations";
import * as publicLocations from "../../constants/publicLocations";
import * as monitoringPerformanceLocations from "../../constants/monitoringPerformance/monitoringPerformanceLocations";
import * as monitoringComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import * as drLocations from "../../DisputeResolution/drLocations";
import AccountUtility from "../../api/AccountUtility";
import {allow} from "../authorization/AuthorizationUtilities";
import CollapsibleContainer from "../common/CollapsibleContainer";
import {isNullOrUndefined} from "../common/commonUtilities";
import * as bpieLocations from "../../containers/bpie/bpieLocations";
import * as gmsLocations from "../../containers/gms/gmsLocations";
import * as ptsLocations from "../../containers/pts/ptsLocations";
import {isFinFacilitator} from "../../containers/bpie/bpieUtilities";
import {connect} from "react-redux";
import {BpiePolicy} from "../authorization/policies/BpiePolicy";
import {GmsPolicy} from "../authorization/policies/GmsPolicy";

export const Menu = ({ isActive, logout, isAdminMenuOpen, adminMenuChange,  handleReturnAsAdmin, finFacilitatorDistricts}) => {
	let menuClass = "";

	if (isActive)
		menuClass = "is-active";

	const currentUser = LocalStorage.getLoggedInUser();
	if(!currentUser) return null;
	const isDistrictUser = AccountUtility.isDistrictUser();
	const isBpieSchoolAdmin = AccountUtility.isBpieSchoolAdmin();
	const isOnlyBpieSchoolAdmin = AccountUtility.isOnlyBpieSchoolAdmin();
	const userProfileLocation = usersLocations.PROFILE.path.replace(usersLocations.USER_ID, currentUser.Id);

	const hasAccessToDisputeResolution = allow(StateComplaintPolicy) || allow(DueProcessPolicy) || allow(MediationPolicy);

	const handleFinishImpersonateClick = (event) => {
		event.preventDefault();
		handleReturnAsAdmin();
	};

	return (
		<nav className={"menu " + menuClass}>
			<div className="menu__container">
				<ul>
					{
						!isOnlyBpieSchoolAdmin &&
						<li><Link to={systemLocations.DASHBOARD.path}>Dashboard</Link></li>
					}

					<Allow context={contexts.USERS}>
						<li><Link to={userProfileLocation}>My Account</Link></li>
					</Allow>

					{
						!isDistrictUser &&
						<Allow context={contexts.BPIE}>
							<li><Link to={bpieLocations.STATE_SBPIE_SEARCH.path}>BPIE</Link></li>
						</Allow>
					}

					{
						isDistrictUser &&
						!isBpieSchoolAdmin &&
						<Allow context={contexts.BPIE}>
							<li><Link to={bpieLocations.DISTRICT_BPIE_SUMMARY.path}>BPIE</Link></li>
						</Allow>
					}
					{
						isBpieSchoolAdmin &&
						<Allow context={contexts.BPIE}>
							<li><Link to={bpieLocations.BPIE_LANDING.path}>BPIE</Link></li>
						</Allow>
					}

					{
						isDistrictUser &&
						hasAccessToDisputeResolution &&
						<li><Link to={drLocations.LANDING_DISTRICT.getUrl(currentUser.InstitutionId)}>Dispute Resolution</Link></li>
					}

					{
						!isDistrictUser &&
						hasAccessToDisputeResolution &&
						<li><Link to={drLocations.DR_SUMMARY.path}>Dispute Resolution</Link></li>
					}

					{
						<Allow context={contexts.GMS}>
							<li><Link to={gmsLocations.GMS_SUMMARY.path}>GMS</Link></li>
						</Allow>
					}

					{
						isDistrictUser &&
						<Allow context={contexts.MONITORING_COMPLIANCE}>
							<li><Link to={monitoringComplianceLocations.LANDING_DISTRICT.path}>Monitoring
								for Compliance</Link></li>
						</Allow>
					}
					{
						!isDistrictUser &&
						<Allow context={contexts.MONITORING_COMPLIANCE}>
							<li><Link to={monitoringComplianceLocations.MC_SUMMARY.path}>Monitoring
								for Compliance</Link></li>
						</Allow>
					}

					{
						isDistrictUser &&
						<Allow context={contexts.MONITORING_PERFORMANCE}>
							<li><Link to={monitoringPerformanceLocations.LANDING_DISTRICT.path}>Monitoring
								for Performance</Link></li>
						</Allow>
					}
					{
						!isDistrictUser &&
						<Allow context={contexts.MONITORING_PERFORMANCE}>
							<li><Link to={monitoringPerformanceLocations.LANDING_DOE.path}>Monitoring for
								Performance</Link></li>
						</Allow>
					}

					{
						isDistrictUser &&
						<Allow context={contexts.SPP}>
							<li><Link to={sppLocations.LANDING_DISTRICT.path}>Policies and Procedures</Link></li>
						</Allow>
					}

					{
						!isDistrictUser &&
						<Allow context={contexts.SPP}>
							<li><Link to={sppLocations.LANDING_DOE.path}>Policies and Procedures</Link></li>
						</Allow>
					}

					{
						<Allow context={contexts.PTS}>
							<li><Link to={ptsLocations.PTS_SUMMARY.path}>PTS</Link></li>
						</Allow>
					}

					{
						<Allow context={contexts.STRATEGIC_PLAN}>
							<li><Link to={strategicPlanLocations.LANDING.path}>Strategic Plan</Link></li>
						</Allow>
					}

					{
						!isDistrictUser &&
						<Allow policy={AdminMenuPolicy} policyEvent={policyEvents.MANAGE} isFinFacilitator={isFinFacilitator(finFacilitatorDistricts)}>
							<li>
								<CollapsibleContainer
									trigger={"Admin Areas"}
									classParentString={"Menu_Collapsible"}
									open={isAdminMenuOpen}
									onOpening={() => adminMenuChange(true)}
									onClosing={() => adminMenuChange(false)}
									overflowWhenOpen={"visible"}
								>
									<ul>
										<Allow policy={BpiePolicy} policyEvent={policyEvents.bpie.admin.MANAGE} isFinFacilitator={isFinFacilitator(finFacilitatorDistricts)}>
											<li><Link to={bpieLocations.STATE_BPIE_IMPORT.path}>BPIE Admin</Link></li>
										</Allow>

										{
											AccountUtility.isStateUserDrAuthorized() &&
											<li><Link to={drLocations.DR_ADMIN_TEAM_MEMBERS.path}>DR Admin</Link></li>
										}

										<Allow policy={GmsPolicy} policyEvent={policyEvents.MANAGE}>
											<li><Link to={gmsLocations.GMS_ADMIN_TEAM_MEMBERS.path}>GMS Admin</Link></li>
										</Allow>

										<Allow policy={PtsPolicy} policyEvent={policyEvents.MANAGE}>
											<li><Link to={ptsLocations.PTS_ADMIN_GMS_USERS.path}>PTS Admin</Link></li>
										</Allow>

										<Allow policy={SppPolicy} policyEvent={policyEvents.MANAGE}>
											<li><Link to={sppLocations.ADMIN_DOCUMENTS.path}>Policies and Procedures Admin</Link></li>
										</Allow>

										<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.MANAGE}>
											<li><Link to={strategicPlanLocations.MANAGE_PLANS.path}>Strategic Plan
												Admin</Link>
											</li>
										</Allow>

										<Allow policy={SurveyPolicy} policyEvent={policyEvents.MANAGE}>
											<li><Link to={surveyLocations.LANDING.path}>Survey Admin</Link></li>
										</Allow>

										<Allow policy={AdminPolicy}>
											<li><Link to={systemLocations.ADMIN_APP_SETTINGS.path}>System
												Admin</Link></li>
										</Allow>

										<Allow policy={UserPolicy} policyEvent={policyEvents.MANAGE}>
											<li><Link to={usersLocations.SEARCH.path}>User Admin</Link></li>
										</Allow>

										<Allow policy={UserPolicy} policyEvent={policyEvents.MANAGE_TEAM_MEMBER_DISTRICT_ASSIGNMENT} isFinFacilitator={isFinFacilitator(finFacilitatorDistricts)}>
											<li><Link to={usersLocations.STATE_USER_DISTRICT_ASSIGNMENTS.path}>Team Member District Assignments</Link></li>
										</Allow>
									</ul>
								</CollapsibleContainer>
							</li>
						</Allow>
					}

					{
						isDistrictUser &&
						<Allow policy={UserPolicy} policyEvent={policyEvents.MANAGE}>
							<li><Link to={usersLocations.SEARCH.path}>User Admin</Link></li>
						</Allow>
					}

					<Allow policy={ReportsPolicy}>
						<li><Link to={reportLocations.REPORTS.path}>Reports</Link></li>
					</Allow>

					{
						!isNullOrUndefined(LocalStorage.getAdminAccessToken()) &&
						<li><Link to={"path_set_in_handler"} onClick={handleFinishImpersonateClick}>Finished Impersonating</Link></li>

					}

					<li><Link onClick={logout} to={publicLocations.USER_SIGN_IN.path}>Sign out</Link></li>

				</ul>
			</div>
		</nav>
	);
};

Menu.propTypes = {
	isActive: PropTypes.bool.isRequired,
	isAdminMenuOpen: PropTypes.bool.isRequired,
	logout: PropTypes.func.isRequired,
	adminMenuChange: PropTypes.func.isRequired,
	handleReturnAsAdmin: PropTypes.func.isRequired,
	finFacilitatorDistricts: PropTypes.array.isRequired
};

Menu.defaultProps = {
	isActive: false,
	isAdminMenuOpen: false
};

const mapStateToProps = (state) => {
	return {
		finFacilitatorDistricts: state.bpie.finFacilitatorDistricts,
	};
};

export default connect(mapStateToProps)(Menu);
