import DashboardApi from "../api/DashboardApi";
import {ajaxCallErrorForDashboard, beginAjaxCallForDashboard} from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function loadBpieTilesSuccess(tiles){
    return { type: actionTypes.LOAD_BPIE_TILES_AJAX_SUCCESS, data: tiles };
}
export function loadBpieTiles(dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getBpieTiles(dateWindowId)
            .then(tiles => {
                dispatch(loadBpieTilesSuccess(tiles));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadGmsTilesSuccess(tiles) {
    return { type: actionTypes.LOAD_GMS_TILES_AJAX_SUCCESS, data: tiles };
}
export function loadGmsTiles(dateWindowId) {
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getGmsTiles(dateWindowId)
            .then(tiles => {
                dispatch(loadGmsTilesSuccess(tiles));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadSppTilesSuccess(tiles){
    return { type: actionTypes.LOAD_SPP_TILES_AJAX_SUCCESS, data: tiles };
}

export function loadSppTiles(routeUrl, dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getSppTiles(routeUrl, dateWindowId)
            .then(tiles => {
                dispatch(loadSppTilesSuccess(tiles));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadMonitoringPerformanceTilesSuccess(tiles){
    return { type: actionTypes.LOAD_MONITORING_PERFORMANCE_TILES_AJAX_SUCCESS, data: tiles };
}

export function loadMonitoringPerformanceTiles(routeUrl, dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getMonitoringPerformanceTiles(routeUrl, dateWindowId)
            .then(tiles => {
                dispatch(loadMonitoringPerformanceTilesSuccess(tiles));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadMonitoringComplianceTilesSuccess(tiles){
    return { type: actionTypes.LOAD_MONITORING_COMPLIANCE_TILES_AJAX_SUCCESS, data: { tiles} };
}

export function loadMonitoringComplianceTiles(dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getMonitoringComplianceTiles(dateWindowId)
            .then(tiles => dispatch(loadMonitoringComplianceTilesSuccess(tiles)) )
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadEditableSurveysByRespondentEmailSuccess(data) {
    return { type: actionTypes.LOAD_EDITABLE_SURVEYS_BY_RESPONDENT_EMAIL_AJAX_SUCCESS, data };
}

export function loadEditableSurveysByRespondentEmail(email){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getEditableSurveysByRespondentEmail(email)
            .then(data => {
                dispatch(loadEditableSurveysByRespondentEmailSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadRespondentByEmailSuccess(data) {
    return { type: actionTypes.LOAD_RESPONDENT_BY_EMAIL_AJAX_SUCCESS, data };
}

export function loadRespondentByEmail(email){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getRespondentByEmail(email)
            .then((data) => {
                dispatch(loadRespondentByEmailSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadMediationTilesSuccess(tiles){
    return { type: actionTypes.LOAD_MEDIATION_TILES_AJAX_SUCCESS, data: tiles };
}
export function loadMediationTiles(dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getMediationTiles(dateWindowId)
            .then(tiles => {
                dispatch(loadMediationTilesSuccess(tiles));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadDueProcessTilesSuccess(tiles){
    return { type: actionTypes.LOAD_DUEPROCESS_TILES_AJAX_SUCCESS, data: tiles };
}
export function loadDueProcessTiles(dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getDueProcessTiles(dateWindowId)
            .then(tiles => {
                dispatch(loadDueProcessTilesSuccess(tiles));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}

export function loadComplaintTilesSuccess(tiles){
    return { type: actionTypes.LOAD_COMPLAINT_TILES_AJAX_SUCCESS, data: tiles };
}
export function loadComplaintTiles(dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCallForDashboard());

        return DashboardApi.getComplaintTiles(dateWindowId)
            .then(tiles => {
                dispatch(loadComplaintTilesSuccess(tiles));
            })
            .catch(error => {
                dispatch(ajaxCallErrorForDashboard(error));
            });
    };
}