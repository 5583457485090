import {
    createDescriptionObjectForList,
    createListFromObject, getListOfPropNames,
} from "../../components/common/commonUtilities";
import {
    createYesNoNotApplicableObject,
    createYesNoObject
} from "../../components/common/inputs/inputUtility";
import {Other} from "../../constants/caseNotes/genericCaseNoteConstants";

export const newIssueId = "NEW_ISSUE";
export const newActionId = "NEW_ACTION";

export const StateComplaintSections = {
    Overview: "Overview",
    Intake: "Intake",
    Timeline: "Timeline",
    Routing: "Routing",
    Outcome: "Outcome",
    Issues: "Issues",
    IssuesActions: "Issues / Actions",
    Actions: "Actions",
    Responses: "Responses",
    Closure: "Closure Letter Process",
    ClosureWithNoActions: "Closure",
    CaseNotes: "Case Notes"
};

export const IntakeRequirementType = {
    Informational: 1,
    Required: 2,
    Conditional: 3,
    ConditionalOptional: 4,
    Optional: 5
};
export const IntakeRequirementTypeList = createListFromObject(IntakeRequirementType);

export const IntakeRequirementTypeDetails = {
    Informational: createDescriptionObjectForList(1, "No response is required.  Display only."),
    Required: createDescriptionObjectForList(2, "Required with a response of Yes or No."),
    Conditional: createDescriptionObjectForList(3, "Required with a response of Yes or No and if Yes selected, adds Not Applicable to all Conditional Optional requirements."),
    ConditionalOptional: createDescriptionObjectForList(4, "Required with a response of Yes or No or Not Applicable, if the Conditional Requirement is No."),
    Optional: createDescriptionObjectForList(5, "Required with a response of Yes, No, or Not Applicable.")
};
export const IntakeRequirementTypeDetailsList = createListFromObject(IntakeRequirementTypeDetails);

export const ExtendRelatedToDueProcess = {
    AllIssues: "All Issues",
    MultipleIssues: "Multiple Issues"
};
export const RadioButtonChoicesOfExtendRelatedToDueProcess = createListFromObject(ExtendRelatedToDueProcess);

export const IsIntakeRequirementMet = createYesNoObject(false);
export const IsIntakeRequirementMetList = createListFromObject(IsIntakeRequirementMet);
export const IsIntakeRequirementMetWithNotApplicable = createYesNoNotApplicableObject();
export const IsIntakeRequirementMetWithNotApplicableList = createListFromObject(IsIntakeRequirementMetWithNotApplicable);

export const ComplainantRelationshipToStudent = {
    Parent: "Parent",
    ParentAdvocate: "Parent Advocate",
    ParentAttorney: "Parent Attorney",
    Other: "Other"
};

export const ComplainantRelationshipToStudentList = getListOfPropNames(ComplainantRelationshipToStudent).map(prop => ComplainantRelationshipToStudent[prop]);

export const ReasonForAssistance =[
    "Disability",
    "Limited English proficiency",
    "Otherwise limited communication skills"
];

export const ResolutionTypeAbeyance = "Abeyance";

export const AbeyanceStatus = {
    NotRequired: createDescriptionObjectForList(1, "Not Required"),
    InAbeyance: createDescriptionObjectForList(2, "In Abeyance"),
    Resolved: createDescriptionObjectForList(3, "Resolved (Dismissed, Withdrawn, Fully Adjudicated)")
};
export const AbeyanceStatusList = createListFromObject(AbeyanceStatus);

export const ResolutionNotOrderedTypes = {
    Dismissed: "Dismissed",
    Withdrawn: "Withdrawn"
};
export const ResolutionNotOrderedTypesList = createListFromObject(ResolutionNotOrderedTypes);

export const ResolutionType =
    {
        AlternativeResolution: "AlternativeResolution",
        ReportOfInquiry: "ReportOfInquiry"
    };
export const ResolutionTypeList = createListFromObject(ResolutionType);
export const OutcomeFindings = [...ResolutionNotOrderedTypesList, ...ResolutionTypeList];

export const IssueType =
    {
        Individual: "Individual",
        Systemic: "Systemic"
    };
export const IssueTypeList = createListFromObject(IssueType);

export const ComplaintActionType = {
    ToBeCompleted: "To Be Completed",
};

export const IssueActionType = {
    Required: "Required",
    Corrective: "Corrective"
};
export const IssueActionTypeList = createListFromObject(IssueActionType);

export const CitationTypes = {
    Federal: "Federal",
    State: "State"
};
export const CitationTypesList = createListFromObject(CitationTypes);


export const ComplaintStatus =
    {
        Open: "0",
        DistrictResponding: "2",
        StateReviewing: "3",
        Closed: "9"
    };
export const ComplaintStatusTypeList = createListFromObject(ComplaintStatus);

export const DispositionDismissed =
    {
        LackOfJurisdiction: "Lack of jurisdiction",
        LackOfRequiredComponents: "Lack of required components",
        NotWithinStatueOfLimitations: "Not within statute of limitations",
        DOAHDocumentation: "DOAH documentation",
        DOAHDocumentationAfterDueProcessHearingRequests: "DOAH documentation after due process hearing requests",
        ResolvedOther: "Resolved - Other",
        Other: "Other"
    };

export const DispositionDismissedList = createListFromObject(DispositionDismissed);

export const DispositionWithdrawn =
    {
        ByComplainant: "By complainant",
        ResolvedLocally: "Resolved locally",
        Mediation: "Mediation",
        DueProcessHearingRequests: "Due process hearing requests",
        ResolvedOther: "Resolved - Other",
        Other: "Other"
    };

export const DispositionWithdrawnList = createListFromObject(DispositionWithdrawn);

export const DispositionOtherRequirement = {
    LackOfJurisdiction: false,
    LackOfRequiredComponents: false,
    NotWithinStatueOfLimitations: false,
    DoahDoc: false,
    DoahDocAfterDueProcess: false,
    ByComplainant: false,
    ResolvedLocally: false,
    Mediation: false,
    DueProcess: false,
    ResolvedOther: true,
    Other: true
};

export const ActionCorrections = [
    "IEP implementation",
    "Compensatory services",
    "Revision/implementation of FBA/BIP",
    "Progress reports",
    "Written notice of refusal to parents",
    "Change of placement",
    "Timely provision of IEE",
    "Timely response to referral/evaluation requests",
    "Staff training",
    "Review of district policies/procedures",
    "Other"
];
export const ActionCorrectionsWithTextField = [
    "Other"
];

export const TimelineRequirements = {
    MediationTask: 10,
    OrderSigned: 50
};

export const TimelineStagesEnum = {
    PreInvestigation: 1,
    Investigation: 2,
    PostInvestigation: 3
};

export const TimelineStagesList = createListFromObject(TimelineStagesEnum);

export const TimelineStages = {
    PreInvestigation: {
        Name: "PreInvestigation",
        Label: "Pre-Investigation Stage"
    },
    Investigation: {
        Name: "Investigation",
        Label: "Investigation Stage"
    },
    PostInvestigation: {
        Name: "PostInvestigation",
        Label: "Post-Investigation Stage"
    }
};

let GeneralCaseNoteType = {
    DraftCorrespondence:  createDescriptionObjectForList(1, "Draft Correspondence"),
    InterviewWithComplainant: createDescriptionObjectForList(2, "Interview (Complainant)"),
    InterviewWithPublicAgency: createDescriptionObjectForList(3, "Interview (Public Agency)"),
    FileReview: createDescriptionObjectForList(4, "File Review"),
    QuestionList: createDescriptionObjectForList(5, "Create Questionnaire/Interview Question List"),
    Collaboration: createDescriptionObjectForList(6, "Collaboration"),
    CaseManagementConference: createDescriptionObjectForList(7, "Case Management Conference"),
    Research: createDescriptionObjectForList(8, "Research"),
    DraftReport: createDescriptionObjectForList(9, "Draft Report")
};
GeneralCaseNoteType[Other] = createDescriptionObjectForList(10, "Other");
export { GeneralCaseNoteType };

export const OtherAreaCaseNoteTypes = {
    RoutingInformation: createDescriptionObjectForList(11, "Routing Information")
};

export const ReadOnlyCaseNoteTypes = {
    CaseAssignment: createDescriptionObjectForList(12, "Case Assignment")
};

export const CaseNoteType = {...GeneralCaseNoteType, ...OtherAreaCaseNoteTypes, ...ReadOnlyCaseNoteTypes};

export const CaseNoteTypeList = createListFromObject(CaseNoteType);

export const TimelineMediationResponseOutcomes = {
    NotInterestedInEarlyResolution: "Not interested in early resolution",
    Mediation: "Mediation",
    Facilitation: "Facilitation",
    LocalResolution: "Local Resolution",
    BothMediationAndFacilitation: "Both Mediation and Facilitation"
};
export const TimelineMediationResponseOutcomesList = createListFromObject(TimelineMediationResponseOutcomes);