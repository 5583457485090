import PropTypes from "prop-types";
import React from 'react';
import {TabPanel} from "../../components/common/TabPanel";
import {Tabs} from "../../components/common/Tabs";
import {renderChildrenWithNewProps} from "../../components/layout/layoutUtility";
import {DISCRETIONARY_PROJECT_QUARTERS_LIST, QUARTERLY_REPORT_STATUS} from "./ptsConstants";
import {isArrayNullOrEmpty, isNullOrUndefined} from "../../components/common/commonUtilities";

export const PtsQuarterTabs = ({
                                   audits,
                                   canModifyDocumentation,
                                   children,
                                   firstQuarterNotApproved,
                                   quarterlyReports
                               }) => {
    if (isArrayNullOrEmpty(quarterlyReports) || isNullOrUndefined(children))
        return null;

    const shouldDisableDocumentationModification = (whichQuarter) => {
        if (whichQuarter !== firstQuarterNotApproved) return true;

        const currentQuarterlyReport = quarterlyReports.find(f => f.quarter === whichQuarter);
        
        return !canModifyDocumentation
            || !currentQuarterlyReport.canEditImplementations
    };

    const activePanel = quarterlyReports.find(f => !f.isQuarterlyReportApproved) ?? quarterlyReports[0];
    const activePanelTitle = DISCRETIONARY_PROJECT_QUARTERS_LIST.find(f => f.value === activePanel.quarter).text;

    return <Tabs tabTitles={DISCRETIONARY_PROJECT_QUARTERS_LIST.map((quarter) => quarter.text)}
                 defaultSelectedTab={activePanelTitle}>
        {
            DISCRETIONARY_PROJECT_QUARTERS_LIST.map((quarter, index) => {
                const tabPanelId = quarter.text.replace(/\s/g, '');
                const selectedQuarterlyReport = quarterlyReports.find(report => report.quarter === quarter.value);
                const quarterlyAudits = audits?.filter(a => a.discretionaryProjectQuarter === quarter.value) ?? [];

                const isModifyDocumentationDisabled = shouldDisableDocumentationModification(quarter.value);

                return <TabPanel key={index} tabPanelId={tabPanelId}>
                    {
                        renderChildrenWithNewProps(children, {
                            isModifyDocumentationDisabled,
                            quarter,
                            quarterlyAudits,
                            selectedQuarterlyReport
                        })
                    }
                </TabPanel>;
            })
        }
    </Tabs>;
};

PtsQuarterTabs.propTypes = {
    audits: PropTypes.array,
    canModifyDocumentation: PropTypes.bool.isRequired,
    children: PropTypes.any,//eslint-disable-line react/forbid-prop-types
    firstQuarterNotApproved: PropTypes.number,
    quarterlyReports: PropTypes.array.isRequired
};