import React from "react";
import PropTypes from 'prop-types';
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import Button from "../../../components/common/buttons/Button";
import CheckBoxList from "../../../components/common/inputs/CheckBoxList";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import {
	appendedCheckboxOtherValueExists,
	convertFormInputObjectToObject,
	useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import {ActionCorrections, ActionCorrectionsWithTextField} from "../drStateComplaintConstants";
import {NotifyUser} from "../../../components/common/NotifyUser";

const DrStateComplaintResponseComponentEdit = ({
	                                               deadline,
	                                               handleClickCancel,
	                                               handleSaveResponseAsync,
	                                               issueId,
	                                               response
                                               }) => {

	const responseForm = useFormInputObject(drObjectFactory.createResponseObject(response));

	const handleClickSave = async () => {
		const otherString = ActionCorrectionsWithTextField.toString();

		if (!appendedCheckboxOtherValueExists(otherString, responseForm.corrections.value))
			return NotifyUser.Warning(`More details are required when ${otherString} is selected.`);

		responseForm.corrections.setValue(responseForm.corrections.value);
		responseForm.deadlineId.setValue(deadline.id);

		let updatedResponse = convertFormInputObjectToObject(responseForm);
		const isSuccess = await handleSaveResponseAsync(updatedResponse, deadline, issueId);

		if (isSuccess)
			handleClickCancel();
	};

	return (
		<>
			<DateTimePickerField
				inputClass={`input--small`}
				label="Date Corrected"
				name="dateCorrected"
				showLabel
				{...responseForm.dateCorrected}
			/>
			<CheckBoxList
				legend={"Corrections"}
				listHasTextField={ActionCorrectionsWithTextField}
				name={"corrections"}
				options={ActionCorrections}
				{...responseForm.corrections}
			/>
			<ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
				<Button name="btnSave"
				        label="Save"
				        buttonType={ButtonTypes.SAVE}
				        onClick={handleClickSave}
				/>
				<Button name="btnCancel"
				        label="Cancel"
				        buttonType={ButtonTypes.CANCEL}
				        onClick={handleClickCancel}
				/>
			</ButtonBar>
		</>
	);
};

DrStateComplaintResponseComponentEdit.propTypes = {
	deadline: PropTypes.object.isRequired,
	handleClickCancel: PropTypes.func.isRequired,
	handleSaveResponseAsync: PropTypes.func.isRequired,
	issueId: PropTypes.string,
	response: PropTypes.object.isRequired
};

export default DrStateComplaintResponseComponentEdit;