import React, {Fragment} from "react";
import PropTypes from 'prop-types';
import Button from "../../../components/common/buttons/Button";
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {emptyGuid} from "../../../constants/config";
import {gridConstants} from "../../../constants/inputConstants";
import {createImplementationScrollId} from "../ptsObjectFactory";
import {PtsDeliverableSummarySourceDocumentationEdit} from "./PtsDeliverableSummarySourceDocumentationEdit";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";

export const PtsDeliverableSummaryQuarter = ({
                                                 activity,
                                                 canEdit,
                                                 category,
                                                 editImplementationId,
                                                 handleFileDelete,
                                                 handleFileDownload,
                                                 handleFileUpload,
                                                 handleOpenImplementationClick,
                                                 isModifyDocumentationDisabled,
                                                 quarter,
                                                 selectedQuarterlyReport
                                             }) => {

    const isEditing = !isNullOrUndefined(editImplementationId);
    const addImplementationScrollId = createImplementationScrollId(emptyGuid);
    const quarterImplementations = activity.implementations.filter(implementation => implementation.implementationQuarter === quarter.value);

    return <>
        <GridRow>
            <GridColumn medium={gridConstants.column.NINE}>
                <p>
                    <strong>{quarter.text} ({selectedQuarterlyReport.datesCovered}) Implementations</strong>
                </p>
                <p>
                    {
                        quarterImplementations.length === 0 &&
                        <>None</>
                    }
                    {
                        quarterImplementations.map((implementation, index) => {
                            const delimiter = index === quarterImplementations.length - 1 ? "" : ", ";
                            const implementationScrollId = createImplementationScrollId(implementation.implementationId);
                            return <Fragment
                                key={`${category.className}_${activity.activityNumber}_${index}`}>
                                <Button
                                    btnClass={`implementation--date`}
                                    buttonType={ButtonTypes.LINK_INLINE}
                                    disabled={isEditing}
                                    inputDescription={`, edit implementation for activity ${activity.activityNumberOutput}`}
                                    label={implementation.implementationDateOutput}
                                    name={`implementation-${implementation.implementationId}`}
                                    onClick={(event) => handleOpenImplementationClick(event, activity.activityId, implementation.implementationId, implementationScrollId)}
                                    showLabel
                                />
                                {delimiter}
                            </Fragment>;
                        })
                    }
                </p>
            </GridColumn>
            <GridColumn medium={gridConstants.column.THREE}>
                <GridRow align={`right`}>
                    {
                        !isEditing && canEdit && selectedQuarterlyReport.canEditImplementations &&
                        <Button
                            buttonType={ButtonTypes.ADD}
                            label={`Add Implementation`}
                            inputDescription={`for activity ${activity.activityNumberOutput}`}
                            name={`btnAddImplementation_${activity.activityId}`}
                            onClick={(event) => handleOpenImplementationClick(event, activity.activityId, "", addImplementationScrollId)}
                            showLabel
                        />
                    }
                </GridRow>
            </GridColumn>
        </GridRow>


        <PtsDeliverableSummarySourceDocumentationEdit
            activityId={activity.activityId}
            handleFileDelete={handleFileDelete}
            handleFileDownload={handleFileDownload}
            handleFileUpload={handleFileUpload}
            isDisabled={isModifyDocumentationDisabled}
            quarterSelected={quarter}
            sourceDocumentationFiles={activity.sourceDocumentationFiles}
        />

    </>;
};

PtsDeliverableSummaryQuarter.propTypes = {
    activity: PropTypes.object,
    canEdit: PropTypes.bool,
    category: PropTypes.object.isRequired,
    editImplementationId: PropTypes.string,
    handleFileDelete: PropTypes.func.isRequired,
    handleFileDownload: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    handleOpenImplementationClick: PropTypes.func.isRequired,
    isModifyDocumentationDisabled: PropTypes.bool,
    quarter: PropTypes.object,
    selectedQuarterlyReport: PropTypes.object
};