import PropTypes from 'prop-types';
import React from "react";
import Tile from "../components/common/Tiles/Tile";
import TileContainer from "../components/common/Tiles/TileContainer";

const ReportContextComponent = ({
	                                dateWindowId,
	                                districtId,
	                                exportReport,
	                                printReport,
	                                isDistrictUser,
	                                districtReportTiles,
	                                stateReportTiles,
	                                reportInfo
                                }) => {

	const handleReport = (event, reportIndex, isState) => {
		event.preventDefault();

		const whichTile = isState
			? stateReportTiles[reportIndex]
			: districtReportTiles[reportIndex];

		const isPrintReport = whichTile.component;

		if (isPrintReport) {
			printReport(whichTile.name, reportInfo.context, isState, dateWindowId, districtId, whichTile.title);
			return;
		}

		let apiUrl = whichTile.api.path;
		exportReport(apiUrl, dateWindowId, districtId);
	};

	return (
		<>
			{
				((!isDistrictUser && districtId) || isDistrictUser) &&
				districtReportTiles &&
				districtReportTiles.length > 0 &&
				<div className={reportInfo.themeCss}>
					<TileContainer title={reportInfo.districtTileContainerTitle} rowTilesLarge={"4"}>
						{
							districtReportTiles.map((tile, i) => {
								return (<Tile key={`${reportInfo.themeCss}-district|${i}`}
								              body={tile.name}
								              onClick={(event) => handleReport(event, i, false)}
								/>);
							})
						}
					</TileContainer>
				</div>
			}
			{
				!isDistrictUser && !districtId &&
				stateReportTiles &&
				stateReportTiles.length > 0 &&
				<div className={reportInfo.themeCss}>
					<TileContainer title={reportInfo.stateTileContainerTitle} rowTilesLarge={"4"}>
						{
							stateReportTiles.map((tile, i) => {
								return (<Tile key={`${reportInfo.themeCss}-state${i}`}
								              body={tile.name}
								              onClick={(event) => handleReport(event, i, true)}
								/>);
							})
						}
					</TileContainer>
				</div>
			}
		</>
	);
};

ReportContextComponent.propTypes = {
	dateWindowId: PropTypes.string.isRequired,
	districtId: PropTypes.string,
	exportReport: PropTypes.func.isRequired,
	printReport: PropTypes.func.isRequired,
	isDistrictUser: PropTypes.bool.isRequired,
	districtReportTiles: PropTypes.arrayOf(PropTypes.object),
	stateReportTiles: PropTypes.arrayOf(PropTypes.object),
	reportInfo: PropTypes.object
};

export default ReportContextComponent;