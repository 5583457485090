import React from 'react';
import PropTypes from 'prop-types';
import HtmlDisplayInline from "../../htmlDisplay/HtmlDisplayInline";
import Notification from "../Notification";
import InputLayout from './InputLayout';
import {inputStyles} from '../../../constants/inputConstants';

const CheckBoxField = ({
                           checked,
                           describedBy,
                           disabled,
                           error,
                           helpText,
                           inputClass,
                           label,
                           labelClass,
                           name,
                           onChange = () => undefined,
                           showLabel = true,
                           value = "",
                           wrapperClass = "",
                           useInputTheme = true
                       }) => {

    return (
        <InputLayout
            error={error}
            label={""}
            name={""}
            showLabel={false}
            useInputTheme={useInputTheme}
            wrapperClass={`${wrapperClass} ${useInputTheme ? inputStyles.checkbox.THEME : ""}`}
        >
            <input
                aria-describedby={describedBy}
                type="checkbox"
                disabled={disabled}
                name={name}
                id={name}
                className={inputClass}
                checked={checked}
                onChange={onChange}
                value={value}
            />
            <label
                htmlFor={name}
                className={labelClass}
            >
                <span className={!showLabel ? "is-visuallyhidden" : ""}><HtmlDisplayInline html={label}/>{helpText &&
                    <Notification helpText={helpText}/>}</span>
            </label>
        </InputLayout>
    );
};

CheckBoxField.propTypes = {
    checked: PropTypes.bool.isRequired,
    describedBy: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helpText: PropTypes.string,
    inputClass: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    showLabel: PropTypes.bool,
    useInputTheme: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    wrapperClass: PropTypes.string
};

export default CheckBoxField;