import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../actions/apiForLocalStateActions";
import * as LayoutActions from "../actions/layoutActions";
import AccountUtility from "../api/AccountUtility";
import * as searchCriteriaActions from '../actions/searchCriteriaActions';
import {
    ReportSearchCriteria
} from "../components/common/searchCriterias/ReportSearchCriteria";
import {isArrayNullOrEmpty} from "../components/common/commonUtilities";
import {openInNewTab} from "../components/common/HtmlUtilities";
import {emptyGuid} from "../constants/config";
import api from "./reportApi";
import * as reportLocations from './reportLocations';
import ReportView from "./ReportView";

const ReportContainer = ({
                             actions,
                             allInstitutionsList,
                             dateWindowsAnnualList,
                             searchCriteria
                         }) => {
    const isDistrictUser = AccountUtility.isDistrictUser();

    const [dataLoaded, setDataLoaded] = useState(false);

    const handleChangeForm = (event) => {
        let criteria = Object.assign({}, searchCriteria);
        criteria[event.target.id] = event.target.value;
        actions.saveSearchCriteria(criteria);
    };

    const exportReport = (apiUrl, dateWindowId, districtId) => {
        if (apiUrl !== "") {
            apiUrl = apiUrl
                .replace(reportLocations.DATE_WINDOW_ID, dateWindowId)
                .replace(reportLocations.DISTRICT_ID, isDistrictUser ? emptyGuid : districtId);

            actions.executeApi(api.getReport, [apiUrl]);
        }
    };

    const printReport = (reportId, context, reportArea, dateWindowId, districtId, title) => {
        const printUrl = reportLocations.REPORTS_PRINT.getUrl(reportId, context, reportArea ? "state" : "district", dateWindowId, !districtId ? emptyGuid : districtId, title);
        openInNewTab(printUrl);
    };

    useEffect(() => {
        if (dataLoaded || isArrayNullOrEmpty(dateWindowsAnnualList)) return;

        let updatedSearchCriteria = {...searchCriteria};

        if (!updatedSearchCriteria.dateWindowId)
            updatedSearchCriteria.dateWindowId = !isArrayNullOrEmpty(dateWindowsAnnualList)
                ? dateWindowsAnnualList[0].value
                : "";

        actions.saveSearchCriteria(updatedSearchCriteria);

        setDataLoaded(true);
    }, [dateWindowsAnnualList]);

    useEffect(() => {
        actions.updatePageTitle(`Reports`);
    }, []);

    if (!searchCriteria)
        return null;

    return <ReportView
        dateWindows={dateWindowsAnnualList}
        districts={allInstitutionsList}
        handleChangeForm={handleChangeForm}
        searchCriteria={searchCriteria}
        isDistrictUser={isDistrictUser}
        exportReport={exportReport}
        printReport={printReport}
    />;
};

const mapStateToProps = (state, props) => {
    const searchCriteria = state.searchCriteria.ReportSearchCriteria || new ReportSearchCriteria();

    return {
        searchCriteria
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, searchCriteriaActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

ReportContainer.propTypes = {
    actions: PropTypes.object,
    dateWindowsAnnualList: PropTypes.arrayOf(PropTypes.object).isRequired,
    allInstitutionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportContainer);
