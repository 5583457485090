import AccountUtility from "../../../api/AccountUtility";
import { DefaultPolicy } from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import { PTS as policyEvents } from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import { verifyAccessToContextAndClaim } from "../AuthorizationUtilities";

class PtsPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.PTS]);
        this.updatePolicy();
    }

    updatePolicy() {
        this._policy[policyEvents.ADMIN.manage] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.IMPLEMENTATIONS.edit] =
            function (allowedContexts, userBehaviors) {
                return AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE, behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.SOURCE_DOCUMENTATION.modify] =
            function (allowedContexts, userBehaviors) {
                return AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.RETURN_ON_INVESTMENT.modify] =
            function (allowedContexts, userBehaviors) {
                return AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.IMPLEMENTATIONS.delete] =
            function (allowedContexts, userBehaviors) {
                return AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE, behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.QUARTERLY_REPORTS.submit] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };
    }

}

export { PtsPolicy, policyEvents };