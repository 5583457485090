import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import api from "../drDueProcessApi";
import PrintTable from "../../../components/common/PrintTable";
import {fetchDate} from "../../../components/shared/sharedDataUtilities";

const FederalReportView = ({
                                    actions,
                                    dateWindowId,
                                    dateWindows,
                                    includeGifted
                                }) => {
    const [federalReport, setFederalReport] = useState(null);

    const handleUpdateFederalReport = (federalReport) => {
        setFederalReport(federalReport);
    };

    const getFederalReport = async () => {
        await actions.executeApi(api.getFederalReport, [dateWindowId, includeGifted])
            .then((results) => handleUpdateFederalReport(results));
    };

    useEffect(() => {
        getFederalReport();
    }, []);

    if (!federalReport)
        return null;

    const reportTitle = includeGifted
        ? `ESE Dispute Resolution Report`
        : `Due Process Hearing Requests – IDEA Part B Dispute Resolution Report`;

    return (
        <>
            <div className={"report-header"}>
                <h2>{fetchDate(dateWindows, dateWindowId)} {reportTitle}</h2>
                <h2>Statewide Report</h2>
            </div>
            <PrintTable className={"table-print-center add-padding"}>
                <thead>
                <tr>
                    <th>District</th>
                    <th>(3) Total # of Due process hearing requests filed</th>
                    <th>(3.1) Resolution Sessions</th>
                    <th>(3.1 a) Written settlement through resolution</th>
                    <th>(3.2) Hearing fully adjudicated</th>
                    <th>(3.2 a) Decided within timelines</th>
                    <th>(3.2 b) Extended Timeline</th>
                    <th>(3.3) Due Process Hearing Requests Pending</th>
                    <th>(3.4) Due Process Hearing Requests withdrawn or dismissed or resolved without a
                        hearing</th>
                    <th>(4) Total number of expedited due process hearing requests</th>
                    <th>(4.1) Resolution Meetings</th>
                    <th>(4.1 a) Written settlement agreements</th>
                    <th>(4.2) Expedited Hearings Fully Adjudicated</th>
                    <th>(4.2 a) Change of placement ordered</th>
                    <th>(4.3) Expedited Due Process Hearing Requests Pending</th>
                    <th>(4.4) Expedited Due Process Hearing Requests withdrawn or dismissed</th>
                </tr>
                </thead>
                <tbody>
                {
                    federalReport.map((district, index) => {
                        return (
                            <tr key={index}>
                                <td>{district.districtName}</td>
                                <td className={"text-center"}>{district.totalDueProcessRequests}</td>
                                <td className={"text-center"}>{district.totalRequestsClosedWithResolutionSessions}</td>
                                <td className={"text-center"}>{district.totalRequestsClosedWithResolutionSessionsWithWrittenSettlements}</td>
                                <td className={"text-center"}>{district.totalRequestsFullyAdjudicated}</td>
                                <td className={"text-center"}>{district.totalRequestsWithinTimeline}</td>
                                <td className={"text-center"}>{district.totalRequestsFullyAdjudicatedWithinExtendedTimeline}</td>
                                <td className={"text-center"}>{district.totalPendingRequests}</td>
                                <td className={"text-center"}>{district.totalWithdrawnDismissed}</td>
                                <td className={"text-center"}>{district.totalExpedited}</td>
                                <td className={"text-center"}>{district.totalExpeditedClosedWithResolutionSessions}</td>
                                <td className={"text-center"}>{district.totalExpeditedClosedWithResolutionSessionsWithWrittenSettlements}</td>
                                <td className={"text-center"}>{district.totalExpeditedFullyAdjudicated}</td>
                                <td className={"text-center"}>{district.totalExpeditedChangeOfPlacement}</td>
                                <td className={"text-center"}>{district.totalExpeditedPending}</td>
                                <td className={"text-center"}>{district.totalExpeditedWithdrawnDismissed}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </PrintTable>
        </>
    );
};

FederalReportView.propTypes = {
    actions: PropTypes.object,
    dateWindowId: PropTypes.string.isRequired,
    dateWindows: PropTypes.array.isRequired,
    includeGifted: PropTypes.bool.isRequired
};

export default FederalReportView;