import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../../../actions/apiForLocalStateActions";
import * as LayoutActions from "../../../../actions/layoutActions";
import * as searchCriteriaActions from "../../../../actions/searchCriteriaActions";
import AccountUtility from "../../../../api/AccountUtility";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import {
    BpieFilterVisibilityRules,
    BpieSearchCriteria
} from "../../../../components/common/searchCriterias/BpieSearchCriteria";
import {DASHBOARD} from "../../../../constants/systemLocations";
import {BpieDistrictNavigation} from "../../BpieDistrictNavigation";
import {BpieFilter} from "../../BpieFilter";
import bpieDistrictApi from "../bpieDistrictApi";
import {createSbpieError} from "../bpieDistrictObjectFactory";
import {BpieDistrictNotApplicableView} from "./BpieDistrictNotApplicableView";
import BpieDistrictSbpieErrorsView from "./BpieDistrictSbpieErrorsView";
import BpieDistrictSummaryView from "./BpieDistrictSummaryView";
import FieldsetLayout from "../../../../components/common/inputs/FieldsetLayout";
import {Link} from "react-router-dom";
import * as bpieLocatoins from "../../bpieLocations";

const BpieDistrictDashboardContainer = ({
                                            actions,
                                            currentLocation,
                                            history,
                                            searchCriteria,
                                        }) => {
    const [pendingSbpies, setPendingSbpies] = useState(null);
    const [sbpieErrors, setSbpieErrors] = useState([]);
    const [selectedPendingSbpies, setSelectedPendingSbpies] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [bpie, setBpie] = useState({});
    const [districtReviewingBpie, setDistrictReviewingBpie] = useState(null);

    const onActivateSelectedSbpies = async (event) => {
        event.preventDefault();
        if (confirm("Are you sure you are ready to activate the selected SBPIE Assessments?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.")) {
            await actions.executeApi(bpieDistrictApi.activateSelectedSbpies, [selectedPendingSbpies])
                .then(() => loadPendingSbpies())
                .catch(() => loadPendingSbpies());
        }
    };

    const loadPendingSbpies = async () => {
        await actions.executeApi(bpieDistrictApi.getSchoolsWithPendingSbpies, [])
            .then((incomingBpies) => {
                setPendingSbpies(incomingBpies || []);
                setSelectedPendingSbpies(incomingBpies?.map(bpie => bpie.msidNumber) || []);
            });
    };

    const loadSbpieErrors = async () => {
        await actions.executeApi(bpieDistrictApi.getSchoolsWithSbpieErrors, [])
            .then((incomingSbpieErrors) => {
                const sanitizedSbpieErrors = incomingSbpieErrors.map(sbpieError => createSbpieError(sbpieError));
                setSbpieErrors(sanitizedSbpieErrors);
            });
    }

    const getSbpieFromError = async (schoolBpieErrorId) => {
        await actions.executeApi(bpieDistrictApi.getSchoolBpieByErrorId, [schoolBpieErrorId])
            .then((result) => {
                setBpie(result);
            });
    }

    const isDistrictBpieStatusDistrictReviewing = async () => {
        await actions.executeApi(bpieDistrictApi.isDistrictBpieStatusDistrictReviewing)
            .then((result) => {
                setDistrictReviewingBpie(result);
            });
    }

    const handleSbpieErrorFixed = async (schoolBpieErrorId) => {
        await actions.executeApi(bpieDistrictApi.removeSchoolBpieError, [schoolBpieErrorId]);

        await loadSbpieErrors();
        await loadPendingSbpies();
    };

    const handleToggleActivate = (event, msidNumber = null, isSelected = null) => {
        const toggledMsidNumber = msidNumber || event.target.value;
        isSelected = isNullOrUndefined(isSelected) ? event.target.checked : isSelected;

        let selected = [...selectedPendingSbpies];
        if (isSelected) {
            if (!selected.some(f => f === toggledMsidNumber))
                selected.push(toggledMsidNumber);
        } else
            selected = selected.filter(f => f !== toggledMsidNumber);

        setSelectedPendingSbpies(selected);
    };

    const handleToggleSelectAll = (event) => {
        event.preventDefault();

        if (pendingSbpies.length === selectedPendingSbpies.length)
            setSelectedPendingSbpies([]);
        else {
            setSelectedPendingSbpies(pendingSbpies.map(bpie => bpie.msidNumber));
        }
    };

    const handleSearchFiltersChange = async (criteria) => {
        actions.saveSearchCriteria(criteria);
    };

    const initializeSearch = () => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting("schoolName");
        criteria.textSearchLabel = "School Name or MSID";
        criteria.setVisibilityRules(BpieFilterVisibilityRules.PendingSbpies);
        actions.saveSearchCriteria(criteria);
    };

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        if (AccountUtility.isBpieSchoolAdmin()
            || !AccountUtility.isDistrictUser()) {
            history.push(DASHBOARD.path);
            return;
        }

        loadPendingSbpies();

        loadSbpieErrors();

        initializeSearch();

        isDistrictBpieStatusDistrictReviewing();

        actions.updatePageTitle("District Summary View");
    }, []);

    return (
        <>
            {districtReviewingBpie &&
                <p className={`page-directions-highlight`}>
                    <strong>There is a <Link to={bpieLocatoins.DISTRICT_DBPIE_INFO.getUrl(districtReviewingBpie.dateWindowId, districtReviewingBpie.districtId, districtReviewingBpie.id)}>DBPIE Assessment</Link> ready to be reviewed.</strong>
                </p>
            }
            <BpieDistrictSbpieErrorsView
                actions={actions}
                bpie={bpie}
                getSbpieFromError={getSbpieFromError}
                handleSbpieErrorFixed={handleSbpieErrorFixed}
                sbpieErrors={sbpieErrors}
            />

            {
                pendingSbpies &&
                pendingSbpies.length > 0 &&
                <FieldsetLayout disabled={isDisabled}>
                    <BpieFilter
                        handleSearchFiltersChange={handleSearchFiltersChange}
                        searchCriteria={searchCriteria}
                    />
                </FieldsetLayout>
            }

            <BpieDistrictSummaryView
                actions={actions}
                handleClickOrder={handleClickOrder}
                handleReloadSchoolAdministrator={loadPendingSbpies}
                handleToggleActivate={handleToggleActivate}
                handleToggleSelectAll={handleToggleSelectAll}
                isDisabled={isDisabled}
                pendingSbpies={searchCriteria.applyFiltersAndOrder(pendingSbpies)}
                onActivateSelectedSbpies={onActivateSelectedSbpies}
                selectedPendingSbpies={selectedPendingSbpies}
                setIsDisabled={setIsDisabled}
            />

            <BpieDistrictNotApplicableView actions={actions}/>

            <FlexSpacer/>

            <BpieDistrictNavigation currentLocation={currentLocation} history={history}/>
        </>
    );
}

const mapStateToProps = (state, props) => {
    const searchCriteria = state.searchCriteria.BpieSearchCriteria || new BpieSearchCriteria();
    const currentLocation = props.location.pathname;

    return {
        currentLocation,
        searchCriteria
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, searchCriteriaActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

BpieDistrictDashboardContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLocation: PropTypes.string,
    history: PropTypes.object,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BpieDistrictDashboardContainer);