import React from "react";
import PropTypes from 'prop-types';
import {NotifyUser} from "../../../components/common/NotifyUser";
import {handleScroll} from "../../../components/common/scrollUtilities";
import {ACTIVITY_CATEGORIES_LIST} from "../ptsConstants";
import {PtsDeliverableSummaryCategory} from "./PtsDeliverableSummaryCategory";
import {DELIVERABLE_SUMMARY_SCROLL_CONFIG} from "./ptsDeliverableSummaryConstants";

export const PtsDeliverableSummaryView = ({
                                              activityId,
                                              canEdit,
                                              canModifyDocumentation,
                                              dataChanged,
                                              districtsList,
                                              editImplementationId,
                                              handleDataChanged,
                                              handleDeleteImplementation,
                                              handleFileDelete,
                                              handleFileDownload,
                                              handleFileUpload,
                                              handleSaveImplementation,
                                              project,
                                              setActivityAndImplementation
                                          }) => {
    const handleOpenImplementationClick = (event, activityId, implementationId = "", implementationScrollId = "") => {
        event.preventDefault();
        setActivityAndImplementation(activityId, implementationId);
        if(implementationScrollId)
            handleScroll(implementationScrollId, DELIVERABLE_SUMMARY_SCROLL_CONFIG);
    }

    const handleCancelImplementationEdit = () => {
        NotifyUser.clear(true);
        setActivityAndImplementation(null, null);
    }

    return (
        <div className={`deliverables-summary`}>
            {
                ACTIVITY_CATEGORIES_LIST.map((category, index) => {
                    return <PtsDeliverableSummaryCategory
                        key={index}
                        activities={project[category.activityPropertyName]}
                        activityId={activityId}
                        canEdit={canEdit}
                        canModifyDocumentation={canModifyDocumentation}
                        category={category}
                        dataChanged={dataChanged}
                        districtsList={districtsList}
                        editImplementationId={editImplementationId}
                        firstQuarterNotApproved={project.firstQuarterNotApproved}
                        handleCancelImplementationEdit={handleCancelImplementationEdit}
                        handleDataChanged={handleDataChanged}
                        handleDeleteImplementation={handleDeleteImplementation}
                        handleFileDelete={handleFileDelete}
                        handleFileDownload={handleFileDownload}
                        handleFileUpload={handleFileUpload}
                        handleOpenImplementationClick={handleOpenImplementationClick}
                        handleSaveImplementation={handleSaveImplementation}
                        quarterlyReports={project.quarterlyReports}
                    />
                })
            }
        </div>
    );
};

PtsDeliverableSummaryView.propTypes = {
    activityId: PropTypes.string,
    canEdit: PropTypes.bool.isRequired,
    canModifyDocumentation: PropTypes.bool.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    editImplementationId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    handleDeleteImplementation: PropTypes.func.isRequired,
    handleFileDelete: PropTypes.func.isRequired,
    handleFileDownload: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    handleSaveImplementation: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    setActivityAndImplementation: PropTypes.func.isRequired
};