import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as classHooks from "../../constants/sharedData/classHooks";
import * as ButtonTypes from "../../constants/ButtonTypes";
import IndicatorForm from "../../components/admin/IndicatorForm";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import GridTable from "../../components/common/GridTable";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import * as indicatorTypes from "../../constants/indicatorTypes";
import {catchError} from "../../actions/actionUtility";
import HtmlDisplayBlock from "../../components/htmlDisplay/HtmlDisplayBlock";

class BeessIndicatorsPage extends React.PureComponent {
    constructor(props){
        super(props);

        this.onCreateIndicator = this.onCreateIndicator.bind(this);
        this.onChangeIndicator = this.onChangeIndicator.bind(this);
        this.onEditIndicator = this.onEditIndicator.bind(this);
        this.onSaveIndicator = this.onSaveIndicator.bind(this);
        this.onCancelIndicator = this.onCancelIndicator.bind(this);
        this.onDeleteIndicator = this.onDeleteIndicator.bind(this);
    }

    componentDidMount() {
        this.props.actions.loadBeessIndicators();
    }

    onCreateIndicator(event) {
        event.preventDefault();
        this.props.actions.createBeessIndicator();
    }

    onChangeIndicator(event, indicatorId) {
        event.preventDefault();

        const changedIndicator = Object.assign({}, this.props.beessIndicators.filter(i => i.id === indicatorId)[0]);

        changedIndicator[event.target.name] = event.target.value;

        this.props.actions.changeBeessIndicator(changedIndicator);
    }

    onEditIndicator(event, indicatorId) {
        event.preventDefault();
        this.props.actions.enterEditMode(indicatorId);
    }

    onSaveIndicator(event, indicatorId) {
        event.preventDefault();

        const savedIndicator = this.props.beessIndicators.filter(i => i.id === indicatorId)[0];

        const savedAction = (this.props.editItemId === "")
            ? () => this.props.actions.tryAddBeessIndicator(savedIndicator)
            : () => this.props.actions.tryUpdateSharedIndicator(savedIndicator);

        savedAction()
            .then(() => {
                this.reloadAfterEvent();
            })
            .catch(errors => catchError(errors));
    }

    reloadAfterEvent() {
        this.props.actions.leaveEditMode();
        this.props.actions.loadBeessIndicators();
    }

    onCancelIndicator(event) {
        event.preventDefault();

        this.reloadAfterEvent();
    }

    onDeleteIndicator(event, indicatorId) {
        event.preventDefault();

        if (confirm("Are you sure you want to delete this BEESS indicator?")) {
            const deletedIndicator = this.props.beessIndicators.filter(i => i.id === indicatorId)[0];

            this.props.actions.tryDeleteSharedIndicator(deletedIndicator, indicatorTypes.BEESS)
                .then(() => {
                    this.reloadAfterEvent();
                })
	            .catch(errors => catchError(errors));
        }
    }

    render() {
        return (
            <section>
                <p>BEESS Indicators which are deleted will not have a delete icon. We soft delete indicators so that
                    contexts which use them can still display the indicator information for previous years after they
                    are deleted.</p>
                <GridTable>
                    <thead>
                    <tr>
                        <th>Identifier</th>
                        <th>Title</th>
                        <th>Indicator</th>
                        <th className="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.beessIndicators.map(ind => {
                            if (this.props.editItemId === ind.id)
                                return (<IndicatorForm key={ind.id}
                                                          indicator={ind}
                                                          isLoading={this.props.isLoading}
                                                          onChangeIndicator={(event) => this.onChangeIndicator(event, ind.id)}
                                                          onSaveIndicator={(event) => this.onSaveIndicator(event, ind.id)}
                                                          onCancelIndicator={this.onCancelIndicator}
                                                       showTitleField={true}
                                />);

                            return (
                                <tr key={ind.id} id={ind.id} className={classHooks.INDICATOR}>
                                    <td>{ind.publicIdentifier}</td>
                                    <td>{ind.title}</td>
                                    <td><HtmlDisplayBlock html={ind.text ? ind.text : ""} /></td>
                                    <td className="button-cell">
                                        {
                                            <ButtonBar position={ButtonBarPositions.TABLE}>
                                                <Button name={"edit|" + ind.id}
                                                        label={"EDIT"}
                                                        showLabel={false}
                                                        onClick={(event) => this.onEditIndicator(event, ind.id)}
                                                        buttonType={ButtonTypes.EDIT}
                                                        disabled={this.props.isLoading || this.props.isEditing}/>

                                                {
                                                    !ind.isDeleted &&
                                                    <Button name={"delete|" + ind.id}
                                                            label={"DELETE"}
                                                            showLabel={false}
                                                            onClick={(event) => this.onDeleteIndicator(event, ind.id)}
                                                            buttonType={ButtonTypes.DELETE}
                                                            disabled={this.props.isLoading || this.props.isEditing}/>
                                                }

                                            </ButtonBar>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                    <tfoot>
                    <tr id={"NEW_INDICATOR"} className={classHooks.INDICATOR}>
                        <td colSpan={4}>
                            <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                                <Tile body={"Add BEESS Indicator"}
                                      onClick={!this.props.isEditing && !this.props.isLoading ? this.onCreateIndicator : null}
                                      isNew
                                      isMedium/>
                            </TileContainer>
                        </td>
                    </tr>
                    </tfoot>
                </GridTable>
            </section>
        );
    }
}

BeessIndicatorsPage.propTypes = {
    actions: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    editItemId: PropTypes.string.isRequired,
    beessIndicators: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        isEditing: state.sharedData.isEditing,
        editItemId: state.sharedData.editItemId,
        beessIndicators: state.sharedData.beessIndicators,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        sharedDataActions
    );

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BeessIndicatorsPage);