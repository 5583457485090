import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import $ from "jquery";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {
    convertToString,
    isArrayNullOrEmpty, isInArray,
    isNullOrUndefined,
    isNumber, isTrimmedStringEmpty
} from "../../../components/common/commonUtilities";
import FormTable from "../../../components/common/FormTable";
import CheckBoxList from "../../../components/common/inputs/CheckBoxList";
import FileUploadButton from "../../../components/common/inputs/FileUploadButton";
import FileViewDelete from "../../../components/common/inputs/FileViewDelete";
import SelectField from "../../../components/common/inputs/SelectField";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {emptyGuid} from "../../../constants/config";
import {DrRoles, RadioButtonYesNoList, RadioButtonYesNoObject} from "../../drConstants";
import {getTeamMemberId} from "../../drUtilities";
import * as drDueProcessConstants from '../drDueProcessConstants';
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as DrDueProcessObjectFactory from "../drDueProcessObjectFactory";
import {
    appendedCheckboxOtherValueExists, clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid, useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import DrDueProcessFinalOrderActivityTimeline from "./DrDueProcessFinalOrderActivityTimeline";
import SectionHeader from "../../../components/common/SectionHeader";

export const DrDueProcessFinalOrderView = ({
                                               deleteFile,
                                               downloadFile,
                                               dueProcess,
                                               handleDataChanged,
                                               handleSave,
                                               handleSelectSection,
                                               isDistrictUser,
                                               readOnly,
                                               uploadFile,
                                               userDrTeamMemberRoles
                                           }) => {
    const dueProcessForm = useFormInputObject(DrDueProcessObjectFactory.createDueProcessObject(dueProcess), () => handleAfterFormChange());
    const [activityTimelineResponses, setActivityTimelineResponses] = useState([]);
    const [isSectionOneComplete, setIsSectionOneComplete] = useState(false);

    const handleClickCancel = (event) => {
        handleSelectSection(event, drDueProcessConstants.DueProcessSections.FinalOrder, true);
    };

    const handleAfterFormChange = () => {
        setIsSectionOneComplete(validateSectionOneComplete());
        handleDataChanged();
    };

    const validateForm = () => {
        clearInputFormErrorFields(dueProcessForm);

        const otherString = drDueProcessConstants.FinalOrderIssuesWithTextField.toString();
        if (!appendedCheckboxOtherValueExists(otherString, dueProcessForm.finalOrderIssues.value))
            dueProcessForm.finalOrderIssues.setError(`More details are required when ${otherString} is selected for Issues.`)

        if (isTrimmedStringEmpty(dueProcessForm.inappropriateAljExtendTimelineExplanation.value) && dueProcessForm.inappropriateAljExtendTimeline.value === RadioButtonYesNoObject.Yes)
            dueProcessForm.inappropriateAljExtendTimelineExplanation.setError("Required.")

        if (isTrimmedStringEmpty(dueProcessForm.resolvedSimilarToPastDecisionsExplanation.value) && dueProcessForm.resolvedSimilarToPastDecisions.value === RadioButtonYesNoObject.No)
            dueProcessForm.resolvedSimilarToPastDecisionsExplanation.setError("Required.")

        let isValid = inputFormIsValid(dueProcessForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const saveFinalOrder = (event) => {
        event.preventDefault();

        if (!validateForm())
            return;

        let result = convertFormInputObjectToObject(dueProcessForm);
        result.finalOrderFiles = dueProcess.finalOrderFiles;
        if(result.dueProcessActivityTimelineModel)
            result.dueProcessActivityTimelineModel.dueProcessActivityTimelineResponseModels =  activityTimelineResponses;

        handleSave(result);
    };

    const uploadFinalOrderFiles = (event) => {
        event.preventDefault();

        const files = event.target.files;
        const fileName = event.target.value.substring(event.target.value.lastIndexOf("\\") + 1);

        uploadFile(files, emptyGuid)
            .then((newFileId) => {
                if (!newFileId) return;

                let finalOrderFiles = dueProcess.finalOrderFiles ? [...dueProcess.finalOrderFiles] : [];
                finalOrderFiles.push(DrDueProcessObjectFactory.createFinalOrderFileObject({finalOrderFileId: newFileId, name: fileName}));

                clearUpload();
                let result = convertFormInputObjectToObject(dueProcessForm);
                result.finalOrderFiles = finalOrderFiles;
                handleSave(result);
            });
    };

    const clearUpload = () => {
        let control = $("#fuFinalOrders");
        control.wrap('<form>').closest('form').get(0).reset();
        control.unwrap();
    };

    const onFileView = (event) => {
        event.preventDefault();

        const fileId = event.target.id;
        downloadFile(fileId);
    };

    const onFileDelete = (event) => {
        event.preventDefault();

        if (!confirm("Are you sure you want to delete this file?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
            return;

        const fileId = event.target.id;

        let finalOrderFiles = dueProcess.finalOrderFiles ? [...dueProcess.finalOrderFiles] : [];
        let result = convertFormInputObjectToObject(dueProcessForm);
        result.finalOrderFiles = finalOrderFiles.filter(f => f.finalOrderFileId !== fileId);
        handleSave(result);
        deleteFile(fileId);
    };

    const isAbleToEditActivityTimelineCompletionDate = (currentRoutingProcessRole) => {
        if(isNullOrUndefined(userDrTeamMemberRoles) || !isNumber(currentRoutingProcessRole) ||  isArrayNullOrEmpty(userDrTeamMemberRoles))
            return false;

        const roleIds = userDrTeamMemberRoles.map(r => r.role);

        return isInArray(roleIds, currentRoutingProcessRole) || isInArray(roleIds, DrRoles.AdminSecretary.id);
    };

    const isCompletionDateDisabled = (activityTimeline, previousRequirementCompletionDateExists) => {
        return readOnly || !isAbleToEditActivityTimelineCompletionDate(activityTimeline.roleResponsibleForCompletion) || !isSectionOneComplete || !previousRequirementCompletionDateExists;
    };

    const handleChangeCompletionDate = (dueProcessActivityTimelineRequirementId, dateCompleted) => {
        let updatedState = [...activityTimelineResponses];
        let updatedRequirement = updatedState.find(d => d.dueProcessActivityTimelineRequirementId === dueProcessActivityTimelineRequirementId);
        if (updatedRequirement) {
            updatedRequirement.responderId = getTeamMemberId(userDrTeamMemberRoles, updatedRequirement.roleResponsibleForCompletion, false);
            updatedRequirement.dateCompleted = dateCompleted;
            setActivityTimelineResponses(updatedState);
            handleDataChanged(true);
        }
    };

    const validateSectionOneComplete = () => {
        if(isTrimmedStringEmpty(dueProcessForm.prevailingParty.value.toString()))
            return false;

        if(isTrimmedStringEmpty(dueProcessForm.parentRepresentationStatus.value.toString()))
            return false;

        if(isTrimmedStringEmpty(dueProcessForm.parentRepresentationStatusOther.value) && convertToString(dueProcessForm.parentRepresentationStatus.value) === drDueProcessConstants.ParentRepresentationStatus.Other.id.toString())
            return false;

        if(isArrayNullOrEmpty(dueProcessForm.finalOrderIssues.value))
            return false;

        const otherString = drDueProcessConstants.FinalOrderIssuesWithTextField.toString();
        if (!appendedCheckboxOtherValueExists(otherString, dueProcessForm.finalOrderIssues.value))
            return false;

        if(isTrimmedStringEmpty(dueProcessForm.finalOrderOrderedActions.value.toString()))
            return false;

        if(isTrimmedStringEmpty(dueProcessForm.inappropriateAljExtendTimeline.value.toString()))
            return false;

        if (isTrimmedStringEmpty(dueProcessForm.inappropriateAljExtendTimelineExplanation.value) && dueProcessForm.inappropriateAljExtendTimeline.value === RadioButtonYesNoObject.Yes)
            return false;

        if(isTrimmedStringEmpty(dueProcessForm.resolvedSimilarToPastDecisions.value.toString()))
            return false;

        if (isTrimmedStringEmpty(dueProcessForm.resolvedSimilarToPastDecisionsExplanation.value) && dueProcessForm.resolvedSimilarToPastDecisions.value === RadioButtonYesNoObject.No)
            return false;

        if(isTrimmedStringEmpty(dueProcessForm.recommendedOversightLessons.value))
            return false;

        return true;
    }

    useEffect(() => {
        if (!dueProcess || !dueProcess.dueProcessActivityTimelineModel || !dueProcess.dueProcessActivityTimelineModel.dueProcessActivityTimelineResponseModels) return;
        const activityTimelineResponseModels = dueProcess.dueProcessActivityTimelineModel.dueProcessActivityTimelineResponseModels;
        if (!isArrayNullOrEmpty(activityTimelineResponseModels) && activityTimelineResponses !== activityTimelineResponseModels)
            setActivityTimelineResponses(activityTimelineResponseModels);

        setIsSectionOneComplete(validateSectionOneComplete());
    }, [dueProcess]);

    return (
        <>
            <SectionHeader>
				<h2>Section I. Final Order Information</h2>
			</SectionHeader>

            {
                !isDistrictUser &&
                <>
                    <FileUploadButton
                        label={"Upload Final Order File"}
                        name={"fuFinalOrders"}
                        onChange={uploadFinalOrderFiles}
                    />

                    {
                        dueProcess.finalOrderFiles &&
                        dueProcess.finalOrderFiles.map((fi) => {
                            return (
                                <FileViewDelete
                                    key={fi.finalOrderFileId}
                                    name={fi.finalOrderFileId}
                                    label={fi.name}
                                    onView={onFileView}
                                    onDelete={readOnly ? null : onFileDelete}
                                    disabled={readOnly}
                                />);
                        })
                    }
                </>
            }

            <FormTable>
                <SelectField
                    name={`prevailingParty`}
                    label={"1. Prevailing Party:"}
                    labelClass={`form-list-indent-1`}
                    medium={6}
                    options={drDueProcessConstants.PrevailingPartyList}
                    {...dueProcessForm.prevailingParty}
                    showLabel
                    disabled={readOnly}
                />

                <SelectField
                    showLabel
                    medium={6}
                    label={"2. Parent Representation Status:"}
                    labelClass={`form-list-indent-1`}
                    name={"parentRepresentationStatus"}
                    options={drDueProcessConstants.ParentRepresentationStatusList}
                    {...dueProcessForm.parentRepresentationStatus}
                    disabled={readOnly}
                />

                {
                    convertToString(dueProcessForm.parentRepresentationStatus.value) === drDueProcessConstants.ParentRepresentationStatus.Other.id.toString() &&
                    <TextField
                        name="parentRepresentationStatusOther"
                        label="2a. Parent Representation Status Other Value:"
                        labelClass={`form-list-indent-2`}
                        showLabel
                        {...dueProcessForm.parentRepresentationStatusOther}
                        columnsMedium={6}
                        disabled={readOnly}
                    />
                }

                <CheckBoxList
                    disabled={readOnly}
                    legend={"3. Adjudicated Issues:"}
                    legendClass={`form-list-indent-1`}
                    listHasTextField={drDueProcessConstants.FinalOrderIssuesWithTextField}
                    name={"finalOrderIssues"}
                    options={drDueProcessConstants.FinalOrderIssues}
                    showLabel
                    {...dueProcessForm.finalOrderIssues}
                />

                <SelectField
                    showLabel
                    medium={6}
                    label={"4. Ordered Actions:"}
                    labelClass={`form-list-indent-1`}
                    name={"finalOrderOrderedActions"}
                    options={RadioButtonYesNoList}
                    {...dueProcessForm.finalOrderOrderedActions}
                    disabled={readOnly}
                />

                <SelectField
                    showLabel
                    medium={6}
                    label={"5. Did the ALJ inappropriately extend timelines?"}
                    labelClass={`form-list-indent-1`}
                    name={"inappropriateAljExtendTimeline"}
                    options={RadioButtonYesNoList}
                    {...dueProcessForm.inappropriateAljExtendTimeline}
                    disabled={readOnly}
                />

                {
                    dueProcessForm.inappropriateAljExtendTimeline.value === RadioButtonYesNoObject.Yes &&
                    <TextAreaEditor
                        disabled={readOnly}
                        label="5a. Please provide an explanation:"
                        labelClass={`form-list-indent-2`}
                        name="inappropriateAljExtendTimelineExplanation"
                        showLabel
                        {...dueProcessForm.inappropriateAljExtendTimelineExplanation}
                    />
                }

                <SelectField
                    showLabel
                    label={"6. Was the due process complaint resolved consistent with past decisions or guidance related to similar situations?"}
                    labelClass={`form-list-indent-1`}
                    name={"resolvedSimilarToPastDecisions"}
                    options={RadioButtonYesNoList}
                    {...dueProcessForm.resolvedSimilarToPastDecisions}
                    disabled={readOnly}
                />

                <TextAreaEditor
                    disabled={readOnly}
                    label="6a. Please provide an explanation:"
                    labelClass={`form-list-indent-2`}
                    name="resolvedSimilarToPastDecisionsExplanation"
                    showLabel
                    {...dueProcessForm.resolvedSimilarToPastDecisionsExplanation}
                />

                <TextAreaEditor
                    disabled={readOnly}
                    label="7. Identify what, if any, information from the due process hearing decision shall be recommended to be incorporated in the FDOE’s oversight or monitoring of LEA programs and providers."
                    labelClass={`form-list-indent-1`}
                    name="recommendedOversightLessons"
                    showLabel
                    {...dueProcessForm.recommendedOversightLessons}
                />

            </FormTable>
            {
                dueProcess.dueProcessActivityTimelineModel &&
                <>
                    <SectionHeader>
                        <h2>Section II. Redaction, Public Notice and SAC Requirements</h2>
                    </SectionHeader>
                    {
                        !validateSectionOneComplete() &&
                        <p>NOTE - Section one needs to be complete before the timeline becomes editable.</p>
                    }
                    <FormTable className={'activityTimeline'}>
                        {
                            activityTimelineResponses.map((activityTimeline, index) => {
                                const previousCompletionDateExists = index > 0 ? !isTrimmedStringEmpty(activityTimelineResponses[index - 1].dateCompleted) : isSectionOneComplete;
                                return (
                                    <DrDueProcessFinalOrderActivityTimeline
                                        activityTimeline={activityTimeline}
                                        handleChangeCompletionDate={handleChangeCompletionDate}
                                        isAbleToEditTimelineCompletionDate={isAbleToEditActivityTimelineCompletionDate(activityTimeline.roleResponsibleForCompletion)}
                                        readOnly={isCompletionDateDisabled(activityTimeline, previousCompletionDateExists)}
                                        key={index}
                                    />
                                );
                            })
                        }
                    </FormTable>
                </>
            }
            {
                !readOnly &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button
                        name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={saveFinalOrder}
                    />

                    <Button name="btnCancel"
                            label="Cancel and Reload"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleClickCancel}/>

                </ButtonBar>
            }
        </>
    );
};

DrDueProcessFinalOrderView.propTypes = {
    deleteFile: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    dueProcess: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    uploadFile: PropTypes.func.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrDueProcessFinalOrderView;