import AccountUtility from "../../api/AccountUtility";
import {allow} from "../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../components/authorization/policies/BpiePolicy";
import {convertToString, createListItem} from "../../components/common/commonUtilities";

export function createFinFacilitatorsList(finFacilitators) {
    let finFacilitatorsList = [];
    for (let facilitator of finFacilitators)
        finFacilitatorsList.push(createListItem(facilitator.userId, `${facilitator.fullName}`));

    return finFacilitatorsList;
}

export function createDistrictContactsList(districtContacts) {
    let districtContactList = [];
    for (let districtContact of districtContacts)
        districtContactList.push(createListItem(districtContact.userId, `${districtContact.firstName} ${districtContact.lastName}`));

    return districtContactList;
}


export function getImplementationStatusSelected(indicator, implementationStatus) {
    return indicator.implementationStatusOptions.find(f => f.value === convertToString(implementationStatus));
}

export function isSupportingEvidenceRequired(indicator, implementationStatus) {
    const selectedOption = getImplementationStatusSelected(indicator, implementationStatus);
    if(!selectedOption) return true;

    return selectedOption.isSupportingEvidenceRequired;
}

export function isSupportingEvidenceEnabled(indicator, implementationStatus) {
    const selectedOption = getImplementationStatusSelected(indicator, implementationStatus);
    if(!selectedOption) return true;

    return selectedOption.isSupportingEvidenceEnabled;
}

export function isFinFacilitator(associatedDistricts) {
    return associatedDistricts && associatedDistricts.length > 0;
}

export function isFinAdmin(associatedDistricts) {
    return !isFinFacilitator(associatedDistricts) && AccountUtility.isBpieStateManageUser();
}

export function createFinFacilitatorDistrictOptions(associatedDistricts) {
    if(!associatedDistricts)
        return [];

    return [...associatedDistricts].map(ins => {
        return createListItem(ins.districtId, ins.name);
    })
}

export function isFinFacilitatorForDistrict(associatedDistricts, districtId) {
    if(!associatedDistricts)
        return false;

    const district = associatedDistricts.find(f => f.districtId === districtId);
    return !!district;
}

export function CanEditDistrictBpie(districtBpie, finFacilitatorDistricts) {
    if(!allow(BpiePolicy, policyEvents.districtBpieAssessments.MODIFY))
        return false;

    return DoesDistrictBpieProgressGivePermissionForRole(districtBpie, finFacilitatorDistricts);
}

function DoesDistrictBpieProgressGivePermissionForRole(districtBpie, finFacilitatorDistricts) {
    if(AccountUtility.isDistrictUser())
        return districtBpie.canDistrictEdit;

    if(isFinAdmin(finFacilitatorDistricts))
        return districtBpie.canFinAdminEdit;

    if(isFinFacilitatorForDistrict(finFacilitatorDistricts, districtBpie.districtId))
        return districtBpie.canFinFacilitatorEdit;

    return false;
}

export function CanSubmitDistrictBpie(districtBpie, finFacilitatorDistricts) {
    if(!allow(BpiePolicy, policyEvents.districtBpieAssessments.SUBMIT))
        return false;

    return DoesDistrictBpieProgressGivePermissionForRole(districtBpie, finFacilitatorDistricts);
}

export function createFinFacilitatorSearchOption(finFacilitator){
    return createListItem(finFacilitator.userId, `${finFacilitator.firstName} ${finFacilitator.lastName}`);
}