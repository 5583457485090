import React from "react";
import PropTypes from 'prop-types';
import {convertToBoolean, isArrayNullOrEmpty, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {CheckBoxList, DateTimePickerField} from "../../../components/common/inputs";
import {clearInputFormErrorFields, inputFormIsValid} from "../../../components/common/inputs/inputUtility";
import NumberField from "../../../components/common/inputs/NumberField";
import TextArea from "../../../components/common/inputs/TextArea";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {gridConstants} from "../../../constants/inputConstants";
import {
    IMPLEMENTATION_DETAILS_INITIAL_VALUE,
    PRODUCT_DISSEMINATION_METHODS_LIST
} from "../ptsConstants";
import {PtsDeliverableSummaryAudienceEdit} from "./PtsDeliverableSummaryAudienceEdit";
import {PtsDeliverableSummaryButtons} from "./PtsDeliverableSummaryButtons";
import {DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG} from "./ptsDeliverableSummaryConstants";

export const PtsDeliverableSummaryProductEdit = ({
                                                     disableFields,
                                                     disableExtraAudienceDetails,
                                                     districtsList,
                                                     handleClickCancelEdit,
                                                     handleClickDelete,
                                                     handleClickSave,
                                                     handleDataChanged,
                                                     hasDetails,
                                                     implementationForm,
                                                     implementationStatusDetailsLabel,
                                                     isAdding
                                                 }) => {
    const validateForm = () => {
        clearInputFormErrorFields(implementationForm);

        if (isTrimmedStringEmpty(implementationForm.implementationName.value))
            implementationForm.implementationName.setError("Required.");

        if (isTrimmedStringEmpty(implementationForm.implementationDetails.value)
            || implementationForm.implementationDetails.value.trim() === IMPLEMENTATION_DETAILS_INITIAL_VALUE.product)
            implementationForm.implementationDetails.setError("Required.");

        if(!hasDetails) {
            if (isTrimmedStringEmpty(implementationForm.implementationDate.value))
                implementationForm.implementationDate.setError("Required.");
        }
        else {
            if (isTrimmedStringEmpty(implementationForm.productDisseminatedDate.value))
                implementationForm.productDisseminatedDate.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.contact.value))
                implementationForm.contact.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.timeAndEffort.value))
                implementationForm.timeAndEffort.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.numberDisseminated.value.toString()))
                implementationForm.numberDisseminated.setError("Required.");

            if (isArrayNullOrEmpty(implementationForm.howProductWasDisseminated.value))
                implementationForm.howProductWasDisseminated.setError("Required.");

            if (isArrayNullOrEmpty(implementationForm.audienceTypes.value))
                implementationForm.audienceTypes.setError("Required.");

            const isIncludeAllDistrictsChecked = convertToBoolean(implementationForm.includeAllDistricts.value);
            if (!disableExtraAudienceDetails && !isIncludeAllDistrictsChecked && isArrayNullOrEmpty(implementationForm.districts.value))
                implementationForm.districts.setError("Required.");

            if (!disableExtraAudienceDetails && isArrayNullOrEmpty(implementationForm.schoolTypes.value))
                implementationForm.schoolTypes.setError("Required.");
        }

        let isValid = inputFormIsValid(implementationForm, DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    return <>
        <TextField label={`Name and Type of Product`} showLabel disabled={disableFields}
                   {...implementationForm.implementationName} />

        <TextArea label={implementationStatusDetailsLabel} showLabel disabled={disableFields}
                  {...implementationForm.implementationDetails} />

        {
            hasDetails &&
            <>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <DateTimePickerField
                            {...implementationForm.productDisseminatedDate}
                            disabled={disableFields}
                            label="Date Product Disseminated:"
                            showLabel
                        />
                    </GridColumn>
                    <GridColumn>
                        <CheckBoxList {...implementationForm.howProductWasDisseminated}

                                      legend={`How was Product Disseminated`} showLegend disabled={disableFields}
                                      options={PRODUCT_DISSEMINATION_METHODS_LIST}/>
                    </GridColumn>
                </GridRow>

                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField label={`Contact`} showLabel disabled={disableFields}
                                   {...implementationForm.contact} />
                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            {...implementationForm.numberDisseminated}
                            decimalPlaces={0}
                            disabled={disableFields}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Number Disseminated`}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>

                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField label={`Time and Effort`} showLabel disabled={disableFields}
                                   {...implementationForm.timeAndEffort} />
                    </GridColumn>
                    <GridColumn>
                        <TextField {...implementationForm.collaborators} label={`Collaborators`} showLabel disabled={disableFields} />
                    </GridColumn>
                </GridRow>

                <PtsDeliverableSummaryAudienceEdit
                    disableExtraAudienceDetails={disableExtraAudienceDetails}
                    disableFields={disableFields}
                    districtsList={districtsList}
                    handleDataChanged={handleDataChanged}
                    implementationForm={implementationForm}
                />
            </>
        }

        <PtsDeliverableSummaryButtons
            disableFields={disableFields}
            handleClickCancelEdit={handleClickCancelEdit}
            handleClickDelete={handleClickDelete}
            handleClickSave={handleClickSave}
            isAdding={isAdding}
            validateForm={validateForm}
        />
    </>;
};

PtsDeliverableSummaryProductEdit.propTypes = {
    disableFields: PropTypes.bool.isRequired,
    disableExtraAudienceDetails: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleClickCancelEdit: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    implementationForm: PropTypes.object.isRequired,
    implementationStatusDetailsLabel: PropTypes.string.isRequired,
    isAdding: PropTypes.bool.isRequired
};