import React from "react";
import PropTypes from 'prop-types';
import {isArrayNullOrEmpty, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DateTimePickerField, RadioField} from "../../../components/common/inputs";
import {clearInputFormErrorFields, inputFormIsValid} from "../../../components/common/inputs/inputUtility";
import NumberField from "../../../components/common/inputs/NumberField";
import TextArea from "../../../components/common/inputs/TextArea";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {gridConstants, inputStyles} from "../../../constants/inputConstants";
import {
    IMPLEMENTATION_DETAILS_INITIAL_VALUE,
    RadioButtonYesNoList
} from "../ptsConstants";
import {PtsDeliverableSummaryAudienceEdit} from "./PtsDeliverableSummaryAudienceEdit";
import {PtsDeliverableSummaryButtons} from "./PtsDeliverableSummaryButtons";
import {
    DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG,
} from "./ptsDeliverableSummaryConstants";
import {validateTotalParticipants} from "./ptsDeliverableSummaryUtilities";

export const PtsDeliverableSummaryTrainingEdit = ({
                                                      disableFields,
                                                      disableExtraAudienceDetails,
                                                      districtsList,
                                                      handleClickCancelEdit,
                                                      handleClickDelete,
                                                      handleClickSave,
                                                      handleDataChanged,
                                                      hasDetails,
                                                      implementationForm,
                                                      implementationStatusDetailsLabel,
                                                      isAdding
                                                  }) => {

    const validateForm = () => {
        clearInputFormErrorFields(implementationForm);

        if (isTrimmedStringEmpty(implementationForm.implementationName.value))
            implementationForm.implementationName.setError("Required.");

        if (isTrimmedStringEmpty(implementationForm.implementationDetails.value)
            || implementationForm.implementationDetails.value.trim() === IMPLEMENTATION_DETAILS_INITIAL_VALUE.training)
            implementationForm.implementationDetails.setError("Required.");

        if(!hasDetails) {
            if (isTrimmedStringEmpty(implementationForm.implementationDate.value))
                implementationForm.implementationDate.setError("Required.");
        }
        else {
            if (isTrimmedStringEmpty(implementationForm.facilitator.value))
                implementationForm.facilitator.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.timeAndEffort.value))
                implementationForm.timeAndEffort.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.correctiveAction.value.toString()))
                implementationForm.correctiveAction.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.numberOfTrainings.value.toString()))
                implementationForm.numberOfTrainings.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.startDate.value))
                implementationForm.startDate.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.endDate.value))
                implementationForm.endDate.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.totalNumberOfTrainingParticipants.value.toString()))
                implementationForm.totalNumberOfTrainingParticipants.setError("Required.");

            if (isArrayNullOrEmpty(implementationForm.audienceTypes.value))
                implementationForm.audienceTypes.setError("Required.");

            if (!disableExtraAudienceDetails && isArrayNullOrEmpty(implementationForm.districts.value))
                implementationForm.districts.setError("Required.");

            if (!disableExtraAudienceDetails && isArrayNullOrEmpty(implementationForm.schoolTypes.value))
                implementationForm.schoolTypes.setError("Required.");

            const {updatedTotalParticipants, hasErrors} = validateTotalParticipants(implementationForm.totalParticipants.value);
            if(hasErrors) {
                implementationForm.totalParticipants.setValue(updatedTotalParticipants);
                implementationForm.totalParticipants.setError("Required.");
            }
        }

        let isValid = inputFormIsValid(implementationForm, DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    return <>
        <TextField label={`Name of Training Event`} showLabel disabled={disableFields}
                   {...implementationForm.implementationName} />

        <TextArea label={implementationStatusDetailsLabel} showLabel disabled={disableFields}
                  {...implementationForm.implementationDetails} />

        {
            hasDetails &&
            <>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <DateTimePickerField
                            {...implementationForm.startDate}
                            disabled={disableFields}
                            label="Start Date:"
                            showLabel
                        />
                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            {...implementationForm.numberOfTrainings}
                            decimalPlaces={0}
                            disabled={disableFields}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Number of Trainings`}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>

                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <DateTimePickerField
                            {...implementationForm.endDate}
                            disabled={disableFields}
                            label="End Date:"
                            showLabel
                        />
                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            {...implementationForm.totalNumberOfTrainingParticipants}
                            decimalPlaces={0}
                            disabled={disableFields}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Total Number of Participants`}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>

                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField label={`Facilitator`} showLabel disabled={disableFields}
                                   {...implementationForm.facilitator} />
                    </GridColumn>
                    <GridColumn>
                        <RadioField
                            {...implementationForm.correctiveAction}
                            direction={inputStyles.radio.direction.HORIZONTAL}
                            disabled={disableFields}
                            legend={"Corrective Actions"}
                            options={RadioButtonYesNoList}
                        />
                    </GridColumn>
                </GridRow>

                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField label={`Time and Effort`} showLabel disabled={disableFields}
                                   {...implementationForm.timeAndEffort} />
                    </GridColumn>
                    <GridColumn>
                        <TextField {...implementationForm.collaborators} label={`Collaborators`} showLabel disabled={disableFields} />
                    </GridColumn>
                </GridRow>

                <PtsDeliverableSummaryAudienceEdit
                    disableExtraAudienceDetails={disableExtraAudienceDetails}
                    disableFields={disableFields}
                    districtsList={districtsList}
                    handleDataChanged={handleDataChanged}
                    implementationForm={implementationForm}
                    includeTotalParticipants
                />
            </>
        }

        <PtsDeliverableSummaryButtons
            disableFields={disableFields}
            handleClickCancelEdit={handleClickCancelEdit}
            handleClickDelete={handleClickDelete}
            handleClickSave={handleClickSave}
            isAdding={isAdding}
            validateForm={validateForm}
        />
    </>;
};

PtsDeliverableSummaryTrainingEdit.propTypes = {
    disableFields: PropTypes.bool.isRequired,
    disableExtraAudienceDetails: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleClickCancelEdit: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    implementationForm: PropTypes.object.isRequired,
    implementationStatusDetailsLabel: PropTypes.string.isRequired,
    isAdding: PropTypes.bool.isRequired
};