import React from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty, partialOptionIndexInArray} from "../commonUtilities";
import CheckBoxField from "./CheckBoxField";
import {NumberField, TextField} from "./index";
import InputLayout from "./InputLayout";
import Select from 'react-select';
import {appendedOtherValue, createFakeEvent, generateOtherValue} from "./inputUtility";

const SelectFieldAdapter = ({
                                align = "middle",
                                defaultOption = "Select an Option",
                                disabled = false,
                                error,
                                helpText,
                                includeDefaultOption = false,
                                includePlaceHolder = true,
                                inputClass = "",
                                isMulti = false,
                                isOtherTextNumberOnly = false,
                                isOtherTextPositiveNumberOnly = true,
                                isOtherTextWholeNumberOnly = true,
                                label,
                                labelClass = "",
                                large,
                                listHasTextField = [],
                                medium,
                                name,
                                onChange,
                                options,
                                otherTextClass = ``,
                                otherTextLabel = ``,
                                placeHolder = "Select an Option",
                                showLabel = true,
                                showSelectAll = false,
                                small,
                                value,
                                wrapperClass = ""
                            }) => {

    const createSelectValue = (selectValue, selectLabel) => ({
        value: selectValue,
        label: selectLabel
    });

    const handleSelectFieldChange = (selectedOption) => {
        const result = createFakeEvent(isMulti ? convertValueArray(selectedOption) : selectedOption.value, name);
        onChange(result);
    };

    const convertValueArray = (selectedOptions) => {
        return selectedOptions.map(opt => (opt.value));
    };

    let selectOptions = options.map((option) => {
        return createSelectValue(option.value, option.text);
    });

    let defaultValue = null
    if (includeDefaultOption) {
        defaultValue = createSelectValue("", defaultOption);
        selectOptions = [defaultValue, ...selectOptions];
    }

    const handleUpdate = (event) => {
        const otherValue = event.target.value;
        const newOtherValue = generateOtherValue(convertedValue, otherValue);
        onChange(createFakeEvent(newOtherValue, name));
    };

    const otherSelectListValueIndex = partialOptionIndexInArray(listHasTextField, value);
    const hasTextField = otherSelectListValueIndex > -1;
    const selectListValue = hasTextField ? listHasTextField[otherSelectListValueIndex] : value;
    const otherTextValue = hasTextField ? appendedOtherValue(value, selectListValue) : "";

    let convertedValue = null;
    if (selectListValue) {
        convertedValue = isMulti
            ? selectListValue.map(opt => (selectOptions.find(f => f.value === opt)))
            : selectOptions.find(f => f.value === selectListValue);
    } else if (includeDefaultOption) {
        convertedValue = defaultValue;
    }

    const handleChangeToggleSelectAll = () => {
        let selectList = [];
        if (options.length !== convertedValue.length)
            selectList = options.map(option => option.value || option);

        const result = createFakeEvent(selectList, name);
        onChange(result);
    };

    const toggleSelectAllLabel = options.length === convertedValue?.length
        ? "Deselect All"
        : "Select All";

    const toggleButtonChecked = options.length === convertedValue?.length;

    return (
        <InputLayout
            align={align}
            disabled={disabled}
            error={error}
            helpText={helpText}
            inputClass={inputClass}
            label={label}
            labelClass={labelClass}
            large={large}
            medium={medium}
            name={name}
            showLabel={showLabel}
            small={small}
            wrapperClass={wrapperClass}
        >
            {
                showSelectAll &&
                <CheckBoxField
                    checked={toggleButtonChecked}
                    wrapperClass={`select-all`}
                    label={toggleSelectAllLabel}
                    name={`${name}_btnToggleSelectAll`}
                    onChange={handleChangeToggleSelectAll}
                    showLabel
                />
            }
            <Select
                className={`react-select`}
                classNamePrefix={`react-select`}
                isDisabled={disabled}
                isMulti={isMulti}
                name={name}
                onChange={handleSelectFieldChange}
                options={selectOptions}
                placeholder={includePlaceHolder ? placeHolder : ``}
                value={convertedValue}
            />
            {
                hasTextField &&
                <>
                    {
                        isOtherTextNumberOnly &&
                        <NumberField
                            disabled={disabled}
                            isPositiveNumberOnly={isOtherTextPositiveNumberOnly}
                            isWholeNumberOnly={isOtherTextWholeNumberOnly}
                            label={otherTextLabel || `Specify Other Text`}
                            name={`${name}_text`}
                            onChange={handleUpdate}
                            showLabel={!isTrimmedStringEmpty(otherTextLabel)}
                            value={otherTextValue}
                            wrapperClass={otherTextClass}
                        />
                    }
                    {
                        !isOtherTextNumberOnly &&
                        <TextField
                            disabled={disabled}
                            label={otherTextLabel || `Specify Other Text`}
                            name={`${name}_text`}
                            onChange={handleUpdate}
                            showLabel={!isTrimmedStringEmpty(otherTextLabel)}
                            value={otherTextValue}
                            wrapperClass={otherTextClass}
                        />
                    }
                </>
            }
        </InputLayout>
    );
};

export const searchableSelectFieldProps = {
    align: PropTypes.string,
    defaultOption: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helpText: PropTypes.string,
    includeDefaultOption: PropTypes.bool,
    includePlaceHolder: PropTypes.bool,
    inputClass: PropTypes.string,
    isOtherTextNumberOnly: PropTypes.bool,
    isOtherTextPositiveNumberOnly: PropTypes.bool,
    isOtherTextWholeNumberOnly: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    large: PropTypes.string,
    listHasTextField: PropTypes.array,
    medium: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    otherTextClass: PropTypes.string,
    otherTextLabel: PropTypes.string,
    placeHolder: PropTypes.string,
    showLabel: PropTypes.bool,
    showSelectAll: PropTypes.bool,
    small: PropTypes.string,
    wrapperClass: PropTypes.string
};

SelectFieldAdapter.propTypes = {
    ...searchableSelectFieldProps,
    isMulti: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array
    ]).isRequired
};

export default SelectFieldAdapter;