/*eslint import/namespace: ['error', { allowComputed: true }]*/
import React from 'react';
import PropTypes from 'prop-types';
import {BPIE} from "../../constants/contexts";
import * as contexts from "../../constants/contexts";
import * as contextDisplay from "../../constants/contextDisplay";
import {UserAssignableBehaviors} from "../../constants/behaviors";
import {isAssignedToProgram} from "../../containers/users/userObjectFactory";
import {
    createListIfExistsInIdArray,
    isArrayNullOrEmpty
} from "../common/commonUtilities";
import FormTable from "../common/FormTable";
import CheckBoxField from "../common/inputs/CheckBoxField";
import GridTable from "../common/GridTable";
import AccountUtility from "../../api/AccountUtility";
import ListManagerField from "../common/inputs/ListManagerField";
import {createProgramsList} from "./programObjectFactory";

const UserProfileBehaviorsForm = ({
                                      canModifyPrograms,
                                      handleBehaviorUpdate,
                                      hasActiveBpie,
                                      isStateUser,
                                      onFormChange,
                                      programsForFiscalAgent,
                                      selectedPrograms,
                                      user,
                                      userBehaviors
                                  }) => {
    const assignedToProgram = isAssignedToProgram(user);

    const programs = createProgramsList(programsForFiscalAgent);

    const tableHeader = () => {
        let headerCells = [];
        let reactKey = "headerCell-";

        headerCells.push(<th key={reactKey}>Component</th>);

        UserAssignableBehaviors.forEach(function (key) {
            reactKey = reactKey + key;

            headerCells.push(<th key={reactKey}>{key}</th>);
        });

        return (<thead>
        <tr>{headerCells}</tr>
        </thead>);
    };

    const tableCheckBox = (contextKey, behaviorKey, userBehaviors, handleBehaviorUpdate, hasActiveBpie) => {
        if (userBehaviors === undefined)
            return null;

        const userBehavior = userBehaviors.filter((ub) => {
            const claimValue = ub.claimValue.split('.')[1];
            const securityArea = ub.securityArea.toLowerCase();

            return securityArea === contexts[contextKey] && claimValue === behaviorKey;
        })[0];

        if (userBehavior === undefined || !userBehavior.enabled)
            return null;

        const isDisabled = hasActiveBpie && contextKey.toLowerCase() === BPIE;

        return (<CheckBoxField
            showLabel={false}
            name={contexts[contextKey] + "-" + behaviorKey}
            label={contextDisplay[contextKey] + " " + behaviorKey + " behavior"}
            checked={userBehavior.selected}
            onChange={handleBehaviorUpdate}
            disabled={isDisabled}
        />);
    };

    const tableBody = () => {
        let rows = [];
        const isDistrictUser = AccountUtility.isDistrictUser();

        Object.keys(contexts).forEach(function (contextKey) {
            let contextCells = [];

            if (contextDisplay.NOT_INCLUDED_IN_USER_BEHAVIORS.includes(contextKey)) return;

            if (contexts.NO_DISTRICTS.includes(contexts[contextKey]) && isDistrictUser) return;

            contextCells.push(<td key={contexts[contextKey]}>{contextDisplay[contextKey]}</td>);

            UserAssignableBehaviors.forEach(function (behaviorKey) {
                const checkbox = tableCheckBox(contextKey, behaviorKey, userBehaviors, handleBehaviorUpdate, hasActiveBpie);

                contextCells.push(<td key={behaviorKey}>{checkbox}</td>);
            });

            rows.push(<tr key={contextKey + "-row"}>{contextCells}</tr>);
        });

        return <tbody>{rows}</tbody>;
    };

    const programManagerLabel = isStateUser
        ? `Associate Programs as a BEESS Liaison`
        : `Associate Programs For District / Institution`;

    return <>
        <section className="user-behaviors">
            <h3>Behaviors</h3>
            <GridTable>
                {tableHeader()}
                {tableBody()}
            </GridTable>
        </section>
        {
            assignedToProgram &&
            !isArrayNullOrEmpty(programs) &&
            <section className="user-account">
                <h3>Program Information</h3>
                <FormTable>
                    <ListManagerField
                        archivedLabel={"Deleted"}
                        disabled={!canModifyPrograms}
                        listItems={programs}
                        managerLabel={programManagerLabel}
                        name={`programsForFiscalAgents`}
                        onChange={onFormChange}
                        listValues={createListIfExistsInIdArray(selectedPrograms, programs)}
                    />
                </FormTable>
            </section>
        }
    </>;
}

UserProfileBehaviorsForm.propTypes = {
    canModifyPrograms: PropTypes.bool.isRequired,
    handleBehaviorUpdate: PropTypes.func.isRequired,
    hasActiveBpie: PropTypes.bool.isRequired,
    isStateUser: PropTypes.bool.isRequired,
    onFormChange: PropTypes.func.isRequired,
    programsForFiscalAgent: PropTypes.array,
    selectedPrograms: PropTypes.array,
    user: PropTypes.object.isRequired,
    userBehaviors: PropTypes.array.isRequired,
};

export default UserProfileBehaviorsForm;