import React from 'react';
import PropTypes from "prop-types";
import {ResourceGlossaryView} from "../../Assessment/ResourceGlossaryView";
import bpieApi from "../../bpieApi";
import {ResourcesLink} from "../sbpieConstants";
import BpieAssignMemberForm from "./BpieAssignMemberForm";
import BpieAssignMemberView from "./BpieAssignMemberView";
import BpieSchoolInfoView from "./BpieSchoolInfoView";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import BpieTileView from "./BpieTileView";
import {BPIE_ASSESSMENT_INTRO} from "../../bpieLocations";
import BpieDownloadButtonsView from "../../BpieDownloadButtonsView";

const BpieSchoolInfoContainer = ({
                                     actions,
                                     handleDataChanged,
                                     history,
                                     loadSchoolBpies,
                                     schoolBpie,
                                     archivedBpies
                                 }) => {

    const handleSaveSchoolInfoDetails = (bpieSchoolInfoDetails) => {
        actions.executeApi(bpieApi.startSchoolBpie, [schoolBpie.schoolBpieId, bpieSchoolInfoDetails])
            .then(() => {
                if (schoolBpie.hasPassedBpieTraining && bpieSchoolInfoDetails.assessmentTeam && bpieSchoolInfoDetails.dateMeetingHeld) {
                    loadSchoolBpies(() => {
                        history.push(BPIE_ASSESSMENT_INTRO.getUrl(schoolBpie.schoolBpieId));
                    });
                }
                else {
                    loadSchoolBpies();
                }
            });
    };

    return (
        <>
            <ResourceGlossaryView resources={ResourcesLink} />

            <BpieSchoolInfoView schoolBpie={schoolBpie}/>
            {
                !schoolBpie.isConcluded &&
                <BpieDownloadButtonsView
                    actions={actions}
                />
            }

            <FlexSpacer/>
            <hr/>
            <FlexSpacer/>

            <BpieTileView
                actions={actions}
                archivedBpies={archivedBpies.filter(f => f.districtId === schoolBpie.districtId && f.msidNumber === schoolBpie.msidNumber)}
            />

            {
                schoolBpie.isConcluded &&
                <BpieAssignMemberView schoolBpie={schoolBpie} />
            }
            {
                !schoolBpie.isConcluded &&
                <BpieAssignMemberForm
                    actions={actions}
                    handleSaveSchoolInfoDetails={handleSaveSchoolInfoDetails}
                    handleDataChanged={handleDataChanged}
                    schoolBpie={schoolBpie}
                />
            }
        </>
    );
}

BpieSchoolInfoContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    history: PropTypes.object,
    loadSchoolBpies: PropTypes.func.isRequired,
    schoolBpie: PropTypes.object.isRequired,
    archivedBpies: PropTypes.array
};

export default BpieSchoolInfoContainer;