import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../../../actions/apiForLocalStateActions";
import * as LayoutActions from "../../../../actions/layoutActions";
import AccountUtility from "../../../../api/AccountUtility";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import {getParamsMultiple} from "../../../../components/layout/getParams";
import {emptyGuid} from "../../../../constants/config";
import {BpieAdminNavigation} from "../../BpieAdminNavigation";
import bpieDistrictApi from "../../District/bpieDistrictApi";
import {BpieDistrictNavigation} from "../../BpieDistrictNavigation";
import * as bpieLocations from "../../bpieLocations";
import {BpieSchoolSummaryView} from "./BpieSchoolSummaryView";
import bpieApi from "../../bpieApi";

const BpieSchoolSummaryContainer = ({
                                        actions,
                                        currentLocation,
                                        districtId,
                                        history,
                                        schoolBpieId,
                                        schoolNumber,
                                    }) => {
    const [schoolDetails, setSchoolDetails] = useState(null);
    const isDistrictUser = AccountUtility.isDistrictUser();
    const userDetails = AccountUtility.getUserDetails();

    const handleCreateSbpie = async (districtId, schoolNumber) => {
        await actions.executeApi(bpieDistrictApi.createSbpie, [districtId, schoolNumber])
            .then(async (createdSchoolBpieId) => {
                await redirectToSchoolSummary(createdSchoolBpieId);
            })
            .catch(async () => {
                await loadSchoolDetails();
            });
    };

    const handleDeleteSbpie = async () => {
        if (!confirm("Are you sure you want to delete this SBPIE?"))
            return;

        actions.executeApi(bpieApi.deleteBpieSchoolAssessment, [schoolDetails.currentSchoolBpieId])
            .then(async () => {
                await redirectToSchoolSummary(schoolDetails.currentSchoolBpieId);
            })
            .catch(async () => {
                await loadSchoolDetails();
            });
    };

    const handleResendNotification = async () => {
        await actions.executeApi(bpieDistrictApi.resendPrincipalNotification, [districtId, schoolDetails.currentSchoolBpieId]);
    };

    const handleActivateSbpie = async (districtId, schoolNumber) => {
        await actions.executeApi(bpieDistrictApi.activateSbpie, [districtId, schoolNumber])
            .then(async (activatedSchoolBpieId) => {
                await redirectToSchoolSummary(activatedSchoolBpieId);
            })
            .catch(async () => {
                await loadSchoolDetails();
            });
    };

    const handleUpdateStatus = async (districtId, schoolNumber, currentBpieStatus) => {
        await actions.executeApi(bpieDistrictApi.updateSbpieStatus, [districtId, schoolNumber, currentBpieStatus])
            .then(async (updatedSchoolBpieId) => {
                await redirectToSchoolSummary(updatedSchoolBpieId);
            })
            .catch(async () => {
                await loadSchoolDetails();
            });
    }

    const redirectToSchoolSummary = async (newSchoolBpieId) => {
        if (newSchoolBpieId.toLowerCase() === schoolBpieId.toLowerCase())
            await loadSchoolDetails();
        else {
            const summaryLocation = isDistrictUser
                ? bpieLocations.DISTRICT_SCHOOL_SUMMARY.getUrl(districtId, schoolNumber, newSchoolBpieId)
                : bpieLocations.STATE_SCHOOL_SUMMARY.getUrl(districtId, schoolNumber, newSchoolBpieId);

            history.push(summaryLocation);
        }
    };

    const loadSchoolDetails = async () => {
        const result = await actions.executeApi(bpieDistrictApi.getSchoolSummary, [districtId, schoolNumber, schoolBpieId]);
        setSchoolDetails(result);
        actions.updatePageTitle(`School Summary - ${result.currentSchoolName} (${result.msidNumber})`);
    };

    useEffect(() => {
        if (AccountUtility.isBpieSchoolAdmin())
            history.push(bpieLocations.BPIE_LANDING.getUrl());

        if (isDistrictUser && districtId !== userDetails.InstitutionId)
            history.push(bpieLocations.DISTRICT_BPIE_SUMMARY.getUrl());

        loadSchoolDetails();
    }, []);

    if (!schoolDetails) return null;

    return (
        <>
            <BpieSchoolSummaryView
                actions={actions}
                currentSchoolBpieId={schoolDetails.currentSchoolBpieId}
                handleActivateSbpie={handleActivateSbpie}
                handleCreateSbpie={handleCreateSbpie}
                handleDeleteSbpie={handleDeleteSbpie}
                handleReloadSchoolAdministrator={loadSchoolDetails}
                handleResendNotification={handleResendNotification}
                handleUpdateStatus={handleUpdateStatus}
                isDistrictUser={isDistrictUser}
                schoolBpieId={schoolBpieId}
                schoolDetails={schoolDetails}
            />
            <FlexSpacer/>
            {
                !isDistrictUser &&
                <BpieAdminNavigation currentLocation={currentLocation} history={history}/>
            }

            {
                isDistrictUser &&
                <BpieDistrictNavigation currentLocation={currentLocation} history={history}/>
            }
        </>
    );
}


const mapStateToProps = (state, props) => {
    const currentLocation = props.location.pathname;
    const multipleParams = getParamsMultiple(currentLocation, [bpieLocations.DISTRICT_SCHOOL_SUMMARY, bpieLocations.STATE_SCHOOL_SUMMARY]);
    const params = !multipleParams ? {} : multipleParams;

    return {
        currentLocation,
        districtId: params.districtId || emptyGuid,
        schoolBpieId: params.schoolBpieId || emptyGuid,
        schoolNumber: params.schoolNumber || "-1"
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

BpieSchoolSummaryContainer.propTypes = {
    actions: PropTypes.object,
    currentLocation: PropTypes.string,
    districtId: PropTypes.string,
    history: PropTypes.object,
    schoolBpieId: PropTypes.string,
    schoolNumber: PropTypes.string,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BpieSchoolSummaryContainer);