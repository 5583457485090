import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AccountUtility from "../../../../api/AccountUtility";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import {
    BpieFilterVisibilityRules,
    BpieSearchCriteria
} from "../../../../components/common/searchCriterias/BpieSearchCriteria";
import * as apiForLocalStateActions from "../../../../actions/apiForLocalStateActions";
import * as accountActions from "../../../../actions/accountActions";
import * as layoutActions from "../../../../actions/layoutActions";
import * as searchCriteriaActions from '../../../../actions/searchCriteriaActions';
import {
    createListItem,
    dynamicSort,
    isArrayNullOrEmpty,
    isNullOrUndefined, removeDuplicatesFromListArray
} from "../../../../components/common/commonUtilities";
import {emptyGuid} from "../../../../constants/config";
import {BpieAdminNavigation} from "../../BpieAdminNavigation";
import {sbpieAssessmentStatusList} from "../../bpieConstants";
import {BpieDistrictNavigation} from "../../BpieDistrictNavigation";
import {BpieFilter} from "../../BpieFilter";
import BpieApi from "../../bpieApi";
import {createExportSummaryFilters} from "../../bpieObjectFactory";
import {SbpieSearchResults} from "./SbpieSearchResults";
import {createFinFacilitatorSearchOption} from "../../bpieUtilities";

const SbpieSearchContainer = ({
                                 actions,
                                 allDistricts,
                                 allDistrictsLoaded,
                                 currentLocation,
                                 dateWindowsAnnualList: dateWindows,
                                 history,
                                 searchCriteria,
                             }) => {
    const [summary, setSummary] = useState(null);
    const [districtUpdated, setDistrictUpdated] = useState("");
    const [dateWindowUpdated, setDateWindowUpdated] = useState("");
    const [schools, setSchools] = useState(null);
    const isDistrictUser = AccountUtility.isDistrictUser();
    const userDetails = AccountUtility.getUserDetails();

    const handleSearch = async (criteria, gotoFirstPage = true) => {
        if(isDistrictUser)
            criteria.district = userDetails.Institution;

        if(!isValidSearch(criteria)) {
            NotifyUser.Warning("Please select either a date window or a district.");
            return;
        }

        const dateWindowId = criteria.dateWindowId || emptyGuid;
        const districtId = !criteria.district ? emptyGuid : allDistricts.find(f => f.text === criteria.district).value;
        const result = await actions.executeApi(BpieApi.getSchoolBpiesSummary, [dateWindowId, districtId]);
        setSummary(result);
        await updateSearchResults(criteria, result, gotoFirstPage);
    };

    const updateSearchResults = async (criteria, currentSummary, gotoFirstPage = true) => {
        if(gotoFirstPage)
            criteria.First();
        if(isDistrictUser)
            criteria.district = userDetails.Institution;

        await initializeSchoolList(schools, criteria);

        await initializeFinFacilitators(currentSummary, criteria);

        actions.saveSearchCriteria(criteria);
    };

    const isValidSearch = (criteria) => {
        return criteria.dateWindowId || criteria.district;
    }

    const autoSearch = async (criteria, gotoFirstPage = true) => {
        if(isDistrictUser || isValidSearch(criteria))
            await handleSearch(criteria, gotoFirstPage);
        else {
            setSummary(null);
            await updateSearchResults(criteria, summary);
        }
    }

    const handleSearchFiltersChange = async (criteria, reloadPage) => {
        if(reloadPage || districtUpdated !== criteria.district || dateWindowUpdated !== criteria.dateWindowId) {
            if(dateWindowUpdated !== criteria.dateWindowId)
                setDateWindowUpdated(criteria.dateWindowId);

            await autoSearch(criteria);
        }
        else
            await updateSearchResults(criteria, summary);
    };

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    const initializeSchoolList = async (schoolBasicDetails, criteria) => {
        if(districtUpdated === criteria.district || !schoolBasicDetails) return;

        if(districtUpdated)
            criteria.schoolNumber = "";
        setDistrictUpdated(criteria.district);

        if(criteria.district)
            criteria.allSchools = schoolBasicDetails.filter(f => f.schoolDistrict === criteria.district)
                .map(schoolInfo => createListItem(schoolInfo.msidNumber, schoolInfo.schoolNameWithMsid));
        else
            criteria.allSchools = [];
    };

    const initializeFinFacilitators = async (currentSummary, criteria) => {
        if(isDistrictUser) {
            criteria.finUsers = null;
            return;
        }

        if(!currentSummary) {
            criteria.finUsers = [];
            return;
        }

        let facilitators = currentSummary.filter(s => !isNullOrUndefined(s.finFacilitator) && !isNullOrUndefined(s.finFacilitator.userId)).map(s => createFinFacilitatorSearchOption(s.finFacilitator));
        facilitators = removeDuplicatesFromListArray(facilitators);
        criteria.finUsers = facilitators.sort(dynamicSort("text"));
        const isFinFacilitatorInList = facilitators.some(s => s.id === criteria.sbpieFinFacilitator);
        if(!isFinFacilitatorInList)
            criteria.sbpieFinFacilitator = "";
    };

    const initializeData = async (criteria) => {
        const schoolBasicDetails = await actions.executeApi(BpieApi.getAllDistinctBasicSchoolDetails);
        setSchools(schoolBasicDetails);

        await initializeSchoolList(schoolBasicDetails, criteria);
        actions.saveSearchCriteria(criteria);

        await autoSearch(criteria, false);
    };

    const handleExport = async (bpieSearchFilters) => {
        const dateWindowId = bpieSearchFilters.dateWindowId || emptyGuid;
        const districtId = !bpieSearchFilters.district ? emptyGuid : allDistricts.find(f => f.text === bpieSearchFilters.district).value;
        await actions.executeApi(BpieApi.exportSchoolBpiesSummarySearchResults, [dateWindowId, districtId, createExportSummaryFilters(bpieSearchFilters)]);
    }

    useEffect(() => {
        if(!allDistrictsLoaded) return;

        let criteria = {...searchCriteria};
        if(!isDistrictUser)
            criteria.setDistrictList(allDistricts);
        else {
            criteria.district = userDetails.Institution;
            criteria.setDistrictList([]);
        }

        criteria.allowAllDateWindows = true;
        criteria.statusSingleOptions = sbpieAssessmentStatusList;
        criteria.textSearchLabel = "School Name or MSID";
        criteria.finUsers = [];
        criteria.setVisibilityRules(BpieFilterVisibilityRules.SbpieSearch);
        initializeData(criteria);

    }, [allDistrictsLoaded]);

    useEffect(() => {

        actions.updatePageTitle(`SBPIE Assessments`);
    }, []);

    const notificationTrigger = <CollapsibleTrigger text={`Status Definitions`}/>;

    if (!searchCriteria || !schools || !searchCriteria.areRulesCurrent(BpieFilterVisibilityRules.SbpieSearch))
        return null;

    return (
        <>
            <BpieFilter
                dateWindows={dateWindows}
                handleExport={handleExport}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            <CollapsibleContainer
                trigger={notificationTrigger}
                id={`status-definitions-container`}
            >
                <ul>
                    <li><strong>Pending</strong> – school is due to complete a triennial SBPIE Assessment</li>
                    <li><strong>Activated</strong> – school administrators have been alerted of the need for SBPIE completion</li>
                    <li><strong>Started</strong> – school has opened the SBPIE Assessment</li>
                    <li><strong>In Progress</strong> – school has begun the SBPIE Assessment process but has not submitted</li>
                    <li><strong>Archived</strong> – schools who have completed the SBPIE Assessment previously</li>
                    <li><strong>Incomplete</strong> – schools who did not begin the SBPIE Assessment during the 3-year cycle or began but did not complete the SBPIE Assessment</li>
                </ul>
            </CollapsibleContainer>
            {
                !isNullOrUndefined(summary) &&
                <SbpieSearchResults
                    actions={actions}
                    handleClickOrder={handleClickOrder}
                    history={history}
                    isDistrictUser={isDistrictUser}
                    searchCriteria={searchCriteria}
                    summary={searchCriteria.applyFiltersAndOrder(summary)}
                    currentLocation={currentLocation}
                />
            }
            {
                isNullOrUndefined(summary) &&
                <div className={`search-results-warning-container`}>
                    <p className={`search-results-warning`}>Please choose a date window or district.</p>
                </div>
            }
            <FlexSpacer />
            {
                !isDistrictUser &&
                <BpieAdminNavigation currentLocation={currentLocation} history={history}/>
            }

            {
                isDistrictUser &&
                <BpieDistrictNavigation currentLocation={currentLocation} history={history}/>
            }
        </>
    );
};

const mapStateToProps = (state, props) => {
    const searchCriteria = state.searchCriteria.BpieSearchCriteria || new BpieSearchCriteria();

    let allDistricts = [...state.sharedData.districtsWithSchools].map(ins => {
        return {value: ins.id, text: ins.name};
    });

    const currentLocation = props.location.pathname;
    const allDistrictsLoaded = !isArrayNullOrEmpty(allDistricts);

    return {
        allDistricts,
        allDistrictsLoaded,
        currentLocation,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        accountActions,
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

SbpieSearchContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
    allDistrictsLoaded: PropTypes.bool.isRequired,
    currentLocation: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SbpieSearchContainer);

