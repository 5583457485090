import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import * as searchCriteriaActions from "../../../actions/searchCriteriaActions";
import AccountUtility from "../../../api/AccountUtility";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {PtsPolicy, policyEvents} from "../../../components/authorization/policies/PtsPolicy";
import Button from "../../../components/common/buttons/Button";
import {
    createListItem,
    isArrayNullOrEmpty,
    isNullOrUndefined,
    setPageTitle
} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import * as LayoutActions from "../../../actions/layoutActions";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {
    PtsFilterVisibilityRules,
    PtsSearchCriteria,
} from "../../../components/common/searchCriterias/PtsSearchCriteria";
import {getParamsMultiple} from "../../../components/layout/getParams";
import {generateDateWindowSelectListItemsAfterYear} from "../../../components/shared/sharedDataUtilities";
import {DASHBOARD} from "../../../constants/systemLocations";
import {PtsFilter} from "../PtsFilter";
import * as ptsLocations from "../ptsLocations";
import {PTS_ADMIN_SECTIONS} from "./ptsAdminConstants";

const PtsAdminWrapper = ({
                             actions,
                             allFiscalAgents,
                             Component,
                             currentLocation,
                             dateWindows,
                             history,
                             isLoading,
                             params,
                             props,
                             searchCriteria,
                         }) => {
    const [dataChanged, setDataChanged] = useState(false);

    const gmsUsersUrl = ptsLocations.PTS_ADMIN_GMS_USERS.getUrl();
    const manageProgramsUrl = ptsLocations.PTS_ADMIN_MANAGE_PROGRAMS.getUrl();

    const pageShowsPtsFilter = [manageProgramsUrl];

    const handleSelectSection = (event, section) => {
        let url = "";
        switch (section) {
            case PTS_ADMIN_SECTIONS.gmsUsers:
                url = gmsUsersUrl;
                break;
            case PTS_ADMIN_SECTIONS.managePrograms:
                url = manageProgramsUrl;
                break;
        }

        if (url)
            navButtonClick(event, url);
    };

    const navButtonClick = (event, location) => {
        event.preventDefault();

        gotoLocation(location);
    };

    const gotoLocation = (location, ignoreDataChanged = false) => {
        if (location === currentLocation) return;

        if (!ignoreDataChanged && dataChanged && !confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        setDataChanged(false);
        NotifyUser.clear(true);

        history.push(location);
    };

    const getSection = (location) => {
        switch (location) {
            case gmsUsersUrl:
                return PTS_ADMIN_SECTIONS.gmsUsers;
            case manageProgramsUrl:
                return PTS_ADMIN_SECTIONS.managePrograms;
        }
    };

    const handleSearchFiltersChange = (criteria) => {
        if (dataChanged && !confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        setDataChanged(false);
        actions.saveSearchCriteria(criteria);
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const getCurrentLocationVisibilityRules = () => {
        let visibilityRules = null;
        switch (currentLocation) {
            case gmsUsersUrl:
                visibilityRules = PtsFilterVisibilityRules.AdminWrapper;
                break;
            case manageProgramsUrl:
                visibilityRules = PtsFilterVisibilityRules.Program;
                break;
            default:
                visibilityRules = PtsFilterVisibilityRules.AdminWrapper;
                break;
        }

        return visibilityRules;
    }

    useEffect(() => {
        if (isNullOrUndefined(currentLocation)) return;

        actions.updatePageTitle(`PTS Admin`);
        setPageTitle(`PTS Admin - ${getSection(currentLocation)}`);
    }, [currentLocation]);

    useEffect(() => {
        if (AccountUtility.isFiscalAgentUser()
            || !AuthorizationUtilities.allow(PtsPolicy, policyEvents.ADMIN.manage)) {
            history.push(DASHBOARD.path);
        }
    }, []);

    useEffect(() => {
        if (isArrayNullOrEmpty(dateWindows) || isArrayNullOrEmpty(allFiscalAgents)) return;

        const visibilityRules = getCurrentLocationVisibilityRules();

        if (!visibilityRules || searchCriteria.areRulesCurrent(visibilityRules)) return;

        let criteria = {...searchCriteria};

        if (!criteria.dateWindowId)
            criteria.dateWindowId = dateWindows[0].value;

        criteria.allowAllDateWindows = false;
        criteria.setVisibilityRules(visibilityRules);
        criteria.setFiscalAgentList(allFiscalAgents);
        actions.saveSearchCriteria(criteria);

    }, [dateWindows, allFiscalAgents, currentLocation]);

    if (!searchCriteria || !searchCriteria.areRulesCurrent(getCurrentLocationVisibilityRules()))
        return null;

    return (
        <GridRow isPageNav>
            <GridColumn
                isPageNavLinks
                isStickyLinks
                large="3"
            >
                <Button
                    btnClass={currentLocation === gmsUsersUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, gmsUsersUrl)}
                    label={PTS_ADMIN_SECTIONS.gmsUsers}
                    name={gmsUsersUrl}
                />
                <Button
                    btnClass={currentLocation === manageProgramsUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, manageProgramsUrl)}
                    label={PTS_ADMIN_SECTIONS.managePrograms}
                    name={manageProgramsUrl}
                />
            </GridColumn>

            <GridColumn
                isPageNavContent
                large="9"
            >
                {
                    pageShowsPtsFilter.find(f => f === currentLocation) &&
                    <PtsFilter
                        dateWindows={dateWindows}
                        handleSearchFiltersChange={handleSearchFiltersChange}
                        searchCriteria={searchCriteria}
                    />
                }
                <Component
                    {...props}
                    {...params}
                    actions={actions}
                    allFiscalAgents={allFiscalAgents}
                    dataChanged={dataChanged}
                    dateWindowId={searchCriteria.dateWindowId}
                    gotoLocation={gotoLocation}
                    handleDataChanged={handleDataChanged}
                    handleSelectSection={handleSelectSection}
                    isLoading={isLoading}
                    searchCriteria={searchCriteria}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const searchCriteria = state.searchCriteria.PtsSearchCriteria || new PtsSearchCriteria();
    const dateWindows = generateDateWindowSelectListItemsAfterYear([...state.sharedData.dateWindows], "2022");
    const currentLocation = props.location.pathname;

    const institutions = [...state.sharedData.institutionsWithDiscretionaryAgencies];
    const allFiscalAgents = institutions.map(ins => createListItem(ins.id, ins.name));

    const multipleParams = getParamsMultiple(currentLocation, ptsLocations.ALL_PTS_ROUTES);
    const params = !multipleParams ? {} : multipleParams;

    return {
        allFiscalAgents,
        Component,
        currentLocation,
        dateWindows,
        params,
        props,
        isLoading: state.ajaxCallsInProgress > 0,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, searchCriteriaActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch),
    };
};

PtsAdminWrapper.propTypes = {
    actions: PropTypes.object,
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object),
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindows: PropTypes.array.isRequired,
    history: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    params: PropTypes.object,
    props: PropTypes.object,
    searchCriteria: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PtsAdminWrapper);