import React from "react";
import PropTypes from 'prop-types';
import {convertToBoolean, isNullOrUndefined, limitTextLength} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {CheckBoxField, FieldsetLayout} from "../../../components/common/inputs";
import NumberField from "../../../components/common/inputs/NumberField";
import SearchableMultiSelectField from "../../../components/common/inputs/SearchableMultiSelectField";
import {gridConstants} from "../../../constants/inputConstants";
import {
    AUDIENCES_LIST,
    SCHOOL_TYPES_LIST
} from "../ptsConstants";

export const PtsDeliverableSummaryAudienceEdit = ({
                                                      disableExtraAudienceDetails,
                                                      disableFields,
                                                      districtsList,
                                                      handleDataChanged,
                                                      includeTotalParticipants = false,
                                                      implementationForm,
                                                  }) => {

    const handleTotalParticipantsChange = (event) => {
        const districtName = event.target.id;
        const updatedTotalParticipants = event.target.value;

        let totalParticipants = [...implementationForm.totalParticipants.value];
        const district = totalParticipants.find(f => f.districtName === districtName);
        if (isNullOrUndefined(district))
            return;

        district.totalParticipants = updatedTotalParticipants;
        implementationForm.totalParticipants.setValue(totalParticipants);
        handleDataChanged();
    };

    const isIncludeAllDistrictsChecked = convertToBoolean(implementationForm.includeAllDistricts.value);

    return <>
        <SearchableMultiSelectField {...implementationForm.audienceTypes} label={`Audience Type`} showLabel
                                    disabled={disableFields}
                                    options={AUDIENCES_LIST}/>

        <SearchableMultiSelectField {...implementationForm.schoolTypes} label={`School Type`} showLabel
                                    disabled={disableExtraAudienceDetails}
                                    options={SCHOOL_TYPES_LIST}/>
        {
            !includeTotalParticipants &&
            <CheckBoxField {...implementationForm.includeAllDistricts}
                            disabled={disableExtraAudienceDetails} checked={isIncludeAllDistrictsChecked}
                           label={`Include all districts`} showLabel value={isIncludeAllDistrictsChecked ? `false` : `true`} />
        }
        <SearchableMultiSelectField {...implementationForm.districts} label={`Districts`} showLabel
                                    disabled={disableExtraAudienceDetails || isIncludeAllDistrictsChecked}
                                    options={districtsList}/>

        {
            implementationForm.totalParticipants.value.length > 0 && includeTotalParticipants &&
            <FieldsetLayout legend={`Input the total participants for each district selected.`} showLegend wrapperClass={`districtTotalParticipants`}>
                <GridRow>
                    {
                        implementationForm.totalParticipants.value.map((district, index) => {
                            return (
                                <GridColumn key={index} medium={gridConstants.column.TWO}
                                            large={gridConstants.column.TWO}>
                                    <NumberField
                                        decimalPlaces={0}
                                        disabled={disableFields}
                                        error={district.error}
                                        isInline={false}
                                        minValue={1}
                                        isWholeNumberOnly
                                        label={limitTextLength(district.districtName, 15)}
                                        name={district.districtName}
                                        onChange={handleTotalParticipantsChange}
                                        showLabel
                                        value={district.totalParticipants}
                                    />
                                </GridColumn>
                            );
                        })
                    }
                </GridRow>
            </FieldsetLayout>
        }
    </>;
};

PtsDeliverableSummaryAudienceEdit.propTypes = {
    disableExtraAudienceDetails: PropTypes.bool.isRequired,
    disableFields: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    implementationForm: PropTypes.object.isRequired,
    includeTotalParticipants: PropTypes.bool
};