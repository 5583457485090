import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {RadioButtonYesNoObject} from "../../drConstants";
import DrStateComplaintIssueView from "./DrStateComplaintIssueView";
import api from "../drStateComplaintsApi";
import {
    generateSelectListFromArray,
    isArrayNullOrEmpty,
    isNullOrUndefined
} from "../../../components/common/commonUtilities";

const DrStateComplaintIssueContainer = ({
                                            actions,
                                            complaint,
                                            readOnly,
                                            handleDeleteAction,
                                            handleSaveActionAsync,
                                            handleScroll,
                                            handleUpdateComplaint,
                                            handleDataChanged
                                        }) => {

    const [issueCodes, setIssueCodes] = useState([]);
    const [studentExceptionalities, setStudentExceptionalities] = useState([]);
    const [currentIssueIds, setCurrentIssueIds] = useState([]);
    const [currentActionIds, setCurrentActionIds] = useState([]);
    const [newIssueId, setNewIssueId] = useState(null);

    const handleSaveIssue = async (issue) => {
        handleDataChanged(false);
        issue.complaintId = complaint.id;
        setNewIssueId("");
        return actions.executeApi(api.saveIssue, [issue])
            .then((updatedComplaint) => {
                handleUpdateComplaint(updatedComplaint);
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    const handleDeleteIssue = (issueId) => {
        actions.executeApi(api.deleteIssue, [complaint.id, issueId])
            .then((updatedComplaint) => {
                handleUpdateComplaint(updatedComplaint);
            });
    };

    const buildCurrentActionIds = () => {
        let actionIds = [];
        for (let issue of complaint.issueModels) {
            for (let action of issue.actionModels)
                actionIds.push({actionId: action.id, issueId: action.issueId});
        }

        if (!isNullOrUndefined(newIssueId)) {
            const newId = getNewId(actionIds.map(f => f.actionId), currentActionIds);
            const actionInfo = actionIds.find(f => f.actionId === newId);
            if (actionInfo)
                setNewIssueId(actionInfo.issueId);
        }

        setCurrentActionIds(actionIds.map(f => f.actionId));
    };

    const getNewId = (dbIds, currentIds) => {
        let newId = "";
        for (let id of dbIds) {
            const newItem = currentIds.find(f => f === id);
            if (!newItem)
                newId = id;
        }

        return newId;
    };

    useEffect(() => {
        api.getIssueCodes()
            .then((result) => setIssueCodes(generateSelectListFromArray(result)));
        api.getStudentExceptionalityTypes()
            .then((result) => setStudentExceptionalities(generateSelectListFromArray(result)));
    }, []);

    useEffect(() => {
        if (!complaint.issueModels || complaint.issueModels.length === 0) return;

        const issueIds = complaint.issueModels.map(m => m.issueId);
        if (!isNullOrUndefined(newIssueId))
            setNewIssueId(getNewId(issueIds, currentIssueIds));

        buildCurrentActionIds();

        setCurrentIssueIds(issueIds);
    }, [complaint]);

    if (!complaint || isArrayNullOrEmpty(issueCodes) || isArrayNullOrEmpty(studentExceptionalities)) return null;

    return <DrStateComplaintIssueView
        dateOrdered={complaint.dateOrdered}
        handleDeleteIssue={handleDeleteIssue}
        handleSaveActionAsync={handleSaveActionAsync}
        handleDeleteAction={handleDeleteAction}
        handleSaveIssue={handleSaveIssue}
        handleScroll={handleScroll}
        handleDataChanged={handleDataChanged}
        hasCorrectiveActions={complaint.hasCorrectiveActions === RadioButtonYesNoObject.Yes}
        issueModels={complaint.issueModels}
        issueCodes={issueCodes}
        newIssueId={newIssueId}
        readOnly={readOnly}
        studentExceptionalities={studentExceptionalities}
    />;
};

DrStateComplaintIssueContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    complaint: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
    handleDeleteAction: PropTypes.func.isRequired,
    handleSaveActionAsync: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired
};

export default DrStateComplaintIssueContainer;
