import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as SurveyAdminActions from "../../actions/surveyAdminActions";
import * as LayoutActions from "../../actions/layoutActions";
import HtmlDisplayInline from "../../components/htmlDisplay/HtmlDisplayInline";
import {getParams} from "../../components/layout/getParams";
import * as config from "../../constants/config";
import {SURVEYS} from "../../constants/contexts";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import {catchError} from "../../actions/actionUtility";
import OrderDragDrop from "../../components/common/OrderDragDrop";
import * as surveyStatuses from "../../constants/surveys/surveyStatuses";

export class OrderSurveyElementsPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            params: {}
        };

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        const params = getParams(this.props.location.pathname, surveyLocations.ORDER_ELEMENTS);

        if (params !== null)
            this.setState({params});

        this.props.actions.tryLoadSurveyDetails(params.surveyInstanceId)
            .then(() => {

                this.props.actions.loadSurveyElements(params.surveyInstanceId);

                this.props.actions.updatePageTitle(this.props.surveyDetails.title + " - Order Elements");
            })
            .catch(error => catchError(error));
    }

    onDragEnd(result) {
         this.props.actions.saveElementOrder(this.state.params.surveyInstanceId, [{
             Id: result.draggableId,
             NewDisplayOrder: result.destination.index,
             DisplayOrder: result.source.index
         }])
             .then(() => {

                 this.props.actions.loadSurveyElements(this.state.params.surveyInstanceId);
             })
             .catch(error => catchError(error));
    }

    getElementSets() {
        let elementSets = this.props.elements.filter(element => element.parentId === this.state.params.parentElementId);

        if(elementSets.length === 0 && !this.props.isLoading) {
            const parentElement = this.props.elements.filter(element => element.componentId === this.state.params.parentElementId);

            if(parentElement.length === 1 && parentElement[0].answerChoices.length > 0) {
                elementSets = parentElement[0].answerChoices.map((choice) => {
                   return {
                       componentId: choice.componentId,
                       labelText: choice.text
                   };
                });
            }
        }

        return elementSets;
    }

    getSectionTitle() {
        const parentElement = this.props.elements.filter(element => element.componentId === this.state.params.parentElementId);

        if(parentElement.length === 0)
            return "Survey Element Sets";

        return "Section - " + parentElement[0].labelText;
    }

    render() {
        if(this.props.surveyDetails.status !== surveyStatuses.Editing)
            return (<p>The survey is current not editable, which means the order cannot be changed.
                If you believe you are seeing this message in error, try reloading the page or
                <HtmlDisplayInline html={config.supportDetails(SURVEYS)} />.
            </p>);

        const elementSets = this.getElementSets();

        if (elementSets.length === 0 && !this.props.isLoading)
            return (<p>There are no elements to order.  Please go back to the Elements page to add new elements for this section.
                If you believe you are seeing this message in error, try reloading the page or
                <HtmlDisplayInline html={config.supportDetails(SURVEYS)} />.
            </p>);

        return (
            <section>
                <h1>{this.getSectionTitle()}</h1>
                <p>Drag the element to update its order.  It automatically saves after you place the element.</p>
                <OrderDragDrop elementIdName={"componentId"} elementSets={elementSets} containerId={"draggable"} onDragEnd={this.onDragEnd} elementContentName={"labelText"}/>
            </section>
        );
    }
}

OrderSurveyElementsPage.propTypes = {
    actions: PropTypes.object.isRequired,
    surveyDetails: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        surveyDetails: state.surveyAdmin.selectedSurveyInstance,
        elements: state.surveyAdmin.instanceElements,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const actions = Object.assign({}, SurveyAdminActions, LayoutActions);

    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSurveyElementsPage);
