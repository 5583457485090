import {convertObjectToFormData, mockResolveApiCall} from "../../api/ApiUtility";
import LocalStorage from "../../components/shared/LocalStorage";
import * as config from "../../constants/config";
import * as ApiUtility from "../../api/ApiUtility";
import AjaxRequestOptions from "../../api/requests/AjaxRequestOptions";
import { RequestTypes } from '../../api/requests/RequestTypes';
import * as mockData from "./bpieMockData";
import {API_ROOT} from "./bpieLocations"
import { BpieApiMessages } from "./bpieConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerBpieApi {
    static getSchoolBpies() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}`,
                RequestTypes.GET));
    }

    static getFinFacilitatorAssociatedDistricts() {
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/facilitators/${user.Id}/districts`,
                RequestTypes.GET));
    }

    static GetFinUsersByDistrict() {
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}/finUsers`,
                RequestTypes.GET));
    }

    static getFinUsers() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/finUsers`,
                RequestTypes.GET));
    }

    static startSchoolBpie(schoolBpieId, schoolInformationDetails) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}/start`,
                RequestTypes.PUT,
                JSON.stringify(schoolInformationDetails),
                BpieApiMessages.SAVE_SCHOOL_BPIE.SUCCESS));
    }

    static startSchoolBpieAssessment(schoolBpieId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}/startAssessment`,
                RequestTypes.PUT,
                JSON.stringify(status),
                BpieApiMessages.SAVE_SCHOOL_BPIE.SUCCESS
            ));
    }

    static submitSchoolBpieAssessment(schoolBpieId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}/submitAssessment`,
                RequestTypes.PUT,
                BpieApiMessages.SAVE_SCHOOL_BPIE.SUCCESS
            ));
    }

    static downloadBpieSchoolAssessment(schoolBpieId) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}/assessment/download`,
                RequestTypes.GET));
    }

    static getSchoolBpiesSummary(dateWindowId, districtId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/schools/sbpies`,
                RequestTypes.GET));
    }

    static deleteBpieSchoolAssessment(schoolBpieId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}/assessment`,
                RequestTypes.DELETE,
            ));
    }

    static exportSchoolBpiesSummarySearchResults(dateWindowId, districtId, bpieSearchFilters) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/schools/sbpies`,
                RequestTypes.POST,
                convertObjectToFormData(bpieSearchFilters),
                BpieApiMessages.SBPIE.DOWNLOAD
            ));
    }

    static getAllDistinctBasicSchoolDetails() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/schools`,
                RequestTypes.GET));
    }

    static getDistrictBpies() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts`,
                RequestTypes.GET));
    }

    static getDistrictBpiesSummary(dateWindowId, districtId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies`,
                RequestTypes.GET));
    }

    static downloadBpieDistrictAssessment(bpieId) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${bpieId}/assessment/download`,
                RequestTypes.GET));
    }

    static saveSchoolAdministrator(bpieId, schoolAdministrator) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${bpieId}/schoolAdministrators`,
                RequestTypes.POST,
                JSON.stringify(schoolAdministrator),
                BpieApiMessages.SAVE_SCHOOL_BPIE.ADMIN_SAVE_SUCCESS));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieApi {
    static getSchoolBpies() {
        return mockResolveApiCall(mockData.getSchoolBpies());
    }

    static getFinFacilitatorAssociatedDistricts() {
        return mockResolveApiCall(mockData.getDistricts());
    }

    static GetFinUsersByDistrict() {
        return mockResolveApiCall(mockData.getFinFacilitators());
    }

    static getFinUsers() {
        return mockResolveApiCall({});
    }

    static startSchoolBpie(schoolBpieId, schoolInformationDetails) {
        mockData.startSchoolBpie(schoolInformationDetails);
        return mockResolveApiCall({}, BpieApiMessages.SAVE_SCHOOL_BPIE.SUCCESS);
    }

    static startSchoolBpieAssessment() {
        mockData.startSchoolBpieAssessment();
        return mockResolveApiCall({}, BpieApiMessages.SAVE_SCHOOL_BPIE.SUCCESS);
    }

    static submitSchoolBpieAssessment() {
        mockData.submitSchoolBpieAssessment();
        return mockResolveApiCall({}, BpieApiMessages.SAVE_SCHOOL_BPIE.SUCCESS);
    }

    static getSchoolBpiesSummary() {
        return mockResolveApiCall(mockData.getSchoolBpies());
    }
    static deleteBpieSchoolAssessment() {
        return mockResolveApiCall({}, BpieApiMessages.SBPIE.DELETE_SUCCESS);
    }

    static exportSbpieSearchResults() {
        return mockResolveApiCall({}, BpieApiMessages.SBPIE.DOWNLOAD);
    }

    static getAllDistinctBasicSchoolDetails() {
        return mockResolveApiCall(mockData.getSchoolBpies());
    }

    static getDistricts() {
        return mockResolveApiCall(mockData.getDistricts());
    }

    static getDistrictBpies() {
        return mockResolveApiCall(mockData.getDistrictBpie());
    }

    static getDistrictBpiesSummary() {
        return mockResolveApiCall(mockData.getDistrictBpiesSummary());
    }

    static saveSchoolAdministrator() {
        return mockResolveApiCall({}, BpieApiMessages.SAVE_SCHOOL_BPIE.ADMIN_SAVE_SUCCESS);
    }
}

const bpieApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieApi : MockBpieApi;
export default bpieApi;