import {mockResolveApiCall} from "../../../api/ApiUtility";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../ptsConstants";
import {API_ROOT} from "../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsDeliverableSummaryApi {
    static saveImplementation(dateWindowId, discretionaryProjectId, activityId, quarter, implementation, activityCategory) {
        const requestType = implementation.implementationId === config.emptyGuid ? RequestTypes.POST : RequestTypes.PUT;
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/activities/${activityId}/quarters/${quarter}/implementations/${implementation.implementationId}/${activityCategory}`,
                requestType,
                JSON.stringify(implementation),
                API_MESSAGES.IMPLEMENTATIONS.saved
            )
        );
    }

    static deleteImplementation(dateWindowId, discretionaryProjectId, activityId, quarter, implementationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/activities/${activityId}/quarters/${quarter}/implementations/${implementationId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.IMPLEMENTATIONS.deleted
            )
        );
    }

    static updateImplementationSourceDocumentation(dateWindowId, discretionaryProjectId, activityId, fileDetails) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/activities/${activityId}/source-documentation`,
                RequestTypes.PUT,
                JSON.stringify(fileDetails)
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsDeliverableSummaryApi {
    static saveImplementation() {
        return mockResolveApiCall([]);
    }

    static deleteImplementation() {
        return mockResolveApiCall([]);
    }

    static updateImplementationSourceDocumentation() {
        return mockResolveApiCall([]);
    }
}

const ptsDeliverableSummaryApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsDeliverableSummaryApi : MockPtsDeliverableSummaryApi;
export default ptsDeliverableSummaryApi;