import React, {Fragment} from "react";
import PropTypes from 'prop-types';
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import {handleScroll} from "../../../components/common/scrollUtilities";
import Collapsible from "../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../components/common/CollapsibleTrigger";
import FormTable from "../../../components/common/FormTable";
import {DisplayField} from "../../../components/common/inputs";
import {ACTIVITY_CATEGORIES} from "../ptsConstants";
import {
    createProductImplementationObject,
    createTrainingImplementationObject,
    createServiceDeliveryImplementationObject
} from "../ptsObjectFactory";
import {PtsQuarterTabs} from "../PtsQuarterTabs";
import {DELIVERABLE_SUMMARY_SCROLL_CONFIG} from "./ptsDeliverableSummaryConstants";
import {PtsDeliverableSummaryImplementationEdit} from "./PtsDeliverableSummaryImplementationEdit";
import {PtsDeliverableSummaryQuarter} from "./PtsDeliverableSummaryQuarter";

export const PtsDeliverableSummaryCategory = ({
                                                  activities,
                                                  activityId,
                                                  canEdit,
                                                  canModifyDocumentation,
                                                  category,
                                                  dataChanged,
                                                  districtsList,
                                                  editImplementationId,
                                                  firstQuarterNotApproved,
                                                  handleCancelImplementationEdit,
                                                  handleDataChanged,
                                                  handleDeleteImplementation,
                                                  handleFileDelete,
                                                  handleFileDownload,
                                                  handleFileUpload,
                                                  handleOpenImplementationClick,
                                                  handleSaveImplementation,
                                                  quarterlyReports
                                              }) => {

    const categoryTrigger = <CollapsibleTrigger text={category.description}/>;
    const isEditing = !isNullOrUndefined(editImplementationId);

    const createImplementation = category.id === ACTIVITY_CATEGORIES.product.id ? createProductImplementationObject :
        category.id === ACTIVITY_CATEGORIES.training.id ? createTrainingImplementationObject : createServiceDeliveryImplementationObject;
    const collapsibleContainerId = `container-${category.className}`;
    const activeQuarter = quarterlyReports.find(f => !f.isQuarterlyReportApproved) ?? quarterlyReports[0];

    return <>
        <Collapsible id={collapsibleContainerId} open trigger={categoryTrigger}
                     className={category.className} openedClassName={category.className}
                     onOpen={() => handleScroll(collapsibleContainerId, DELIVERABLE_SUMMARY_SCROLL_CONFIG)} onClose={() => handleScroll(collapsibleContainerId, DELIVERABLE_SUMMARY_SCROLL_CONFIG)}>
            <FormTable>
                {
                    activities.length === 0 &&
                    <p>No activities available for this category.</p>
                }
                {
                    activities.map((activity, index) => {
                        const implementation = activity.implementations.find(implementation => implementation.implementationId === editImplementationId)
                            ?? createImplementation();
                        const activityScrollId = `a-${activity.activityId}`;
                        return (
                            <DisplayField
                                key={`${index}_${category.className}`}
                                label={`Activity ${activity.activityNumber}`}
                                name={`activity-${activity.activityNumber}`}
                                showLabel={false}
                            >
                                <p id={activityScrollId}><strong>{activity.activityNumberOutput} - {activity.activityTitle}</strong></p>
                                <p><strong>Description:</strong> {activity.activityDescription}</p>

                                <PtsQuarterTabs canModifyDocumentation={canModifyDocumentation} quarterlyReports={quarterlyReports} firstQuarterNotApproved={firstQuarterNotApproved}>
                                    <PtsDeliverableSummaryQuarter
                                        activity={activity}
                                        canEdit={canEdit}
                                        category={category}
                                        editImplementationId={editImplementationId}
                                        handleFileDelete={handleFileDelete}
                                        handleFileDownload={handleFileDownload}
                                        handleFileUpload={handleFileUpload}
                                        handleOpenImplementationClick={handleOpenImplementationClick}
                                    />
                                </PtsQuarterTabs>

                                {
                                    isEditing && activity.activityId === activityId &&
                                    <PtsDeliverableSummaryImplementationEdit
                                        activity={activity}
                                        activityScrollId={activityScrollId}
                                        canEdit={canEdit}
                                        category={category}
                                        dataChanged={dataChanged}
                                        districtsList={districtsList}
                                        handleCancelImplementationEdit={handleCancelImplementationEdit}
                                        handleDataChanged={handleDataChanged}
                                        handleDeleteImplementation={handleDeleteImplementation}
                                        handleSaveImplementation={handleSaveImplementation}
                                        quarter={activeQuarter.quarter}
                                        implementation={implementation}
                                    />
                                }
                            </DisplayField>
                        );
                    })
                }
            </FormTable>
        </Collapsible>
    </>;
};

PtsDeliverableSummaryCategory.propTypes = {
    activities: PropTypes.array,
    activityId: PropTypes.string,
    canEdit: PropTypes.bool.isRequired,
    canModifyDocumentation: PropTypes.bool.isRequired,
    category: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    editImplementationId: PropTypes.string,
    firstQuarterNotApproved: PropTypes.number,
    handleCancelImplementationEdit: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDeleteImplementation: PropTypes.func.isRequired,
    handleFileDelete: PropTypes.func.isRequired,
    handleFileDownload: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    handleOpenImplementationClick: PropTypes.func.isRequired,
    handleSaveImplementation: PropTypes.func.isRequired,
    quarterlyReports: PropTypes.array.isRequired
};