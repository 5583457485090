import React from "react";
import PropTypes from 'prop-types';
import Allow from "../components/authorization/Allow";
import GridColumn from "../components/common/GridColumn";
import GridRow from "../components/common/GridRow";
import SelectField from "../components/common/inputs/SelectField";
import {allReports} from "./reportConfiguration";
import ReportContextComponent from "./ReportContextComponent";
import {gridConstants} from "../constants/inputConstants";

export const ReportView = ({
	                    dateWindows,
						districts,
						handleChangeForm,
						searchCriteria,
	                    isDistrictUser,
	                    exportReport,
	                    printReport
                    }) => {
	return (
		<>
			<GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
				<GridColumn>
					<SelectField
						showLabel
						label={`Date Window`}
						name={`dateWindowId`}
						options={dateWindows}
						includeDefaultOption={false}
						value={searchCriteria.dateWindowId}
						onChange={handleChangeForm}
					/>
				</GridColumn>
				{
					!isDistrictUser &&
					<GridColumn>
						<SelectField
							showLabel
							name="districtId"
							label="District / Institution"
							options={districts}
							onChange={handleChangeForm}
							value={searchCriteria.districtId}
						/>
					</GridColumn>
				}
			</GridRow>
			{
				allReports.map((report, index) => {
					return (
						<Allow context={report.info.context} key={index}>
							<ReportContextComponent
								dateWindowId={searchCriteria.dateWindowId}
								districtId={searchCriteria.districtId}
								exportReport={exportReport}
								printReport={printReport}
								isDistrictUser={isDistrictUser}
								districtReportTiles={report.districtReports}
								stateReportTiles={report.stateReports}
								reportInfo={report.info}
							/>
						</Allow>);
				})
			}
		</>
	);
};

ReportView.propTypes = {
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	districts: PropTypes.arrayOf(PropTypes.object).isRequired,
	handleChangeForm: PropTypes.func.isRequired,
	searchCriteria: PropTypes.object.isRequired,
	isDistrictUser: PropTypes.bool.isRequired,
	exportReport: PropTypes.func.isRequired,
	printReport: PropTypes.func.isRequired
};

export default ReportView;