import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import CollapsibleContainer from "../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../components/common/CollapsibleTrigger";
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import DisplayField from "../../../components/common/inputs/DisplayField";
import SelectField from "../../../components/common/inputs/SelectField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import ProgressBar from "../../../components/common/ProgressBar";
import {getDifferentBetweenDatesInDays, outputShortDateFormat} from "../../../components/shared/sharedDataUtilities";
import {InsideBar} from "../../../constants/progressBarTextPositions";
import drApi from "../../drApi";
import {createDueProcessCaseNumberList} from "../../drObjectFactory";
import {
    AbeyanceStatus,
    AbeyanceStatusList, RadioButtonChoicesOfExtendRelatedToDueProcess,
    StateComplaintSections,
    TimelineStages,
    TimelineStagesEnum,
    TimelineStagesList
} from "../drStateComplaintConstants";
import DrStateComplaintNextSteps from "../DrStateComplaintNextSteps";
import {
    getAbeyanceStatusBasedUponResolutionTypeAndAbeyanceStartDate,
    getNewResolutionTypeBasedUponAbeyanceStatus,
    isAbleToEditTimelineCompletionDate
} from "../StateComplaintsUtilities";
import DrStateComplaintTimelineRequirement from "./DrStateComplaintTimelineRequirement";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import FormTable from "../../../components/common/FormTable";
import TextField from "../../../components/common/inputs/TextField";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import {styles as ActionButton} from "../../../constants/ActionButton";
import SectionHeader from "../../../components/common/SectionHeader";

const DrStateComplaintTimelineView = ({
                                          complaint,
                                          dateFiled,
                                          handleChangeCompletionDate,
                                          handleChangeMediationResponse,
                                          handleSaveTimeline,
                                          handleSelectSection,
                                          handleDataChanged,
                                          hasNonAbeyanceExtension,
                                          readOnly,
                                          scrollTimelineView,
                                          stageOpen,
                                          timelineModel,
                                          timelineResponses,
                                          userDrTeamMemberRoles
                                      }) => {
    const [dueProcessCaseNumbers, setDueProcessCaseNumbers] = useState([]);
    const timelineForm = useFormInputObject(drObjectFactory.createTimelineObject(timelineModel || {}), () => handleDataChanged());
    const abeyanceForm = useFormInputObject(drObjectFactory.createAbeyanceObject(), () => handleDataChanged());

    const clearOutFieldsAfterSave = () => {
        timelineForm.extendedTimelineDate.setValue(undefined);
        timelineForm.removeExtension.setValue(false);
        timelineForm.reasonForExtension.setValue('');
    };

    const handleAbeyanceStatusChange = (event) => {
        event.preventDefault();
        const abeyanceStatus = parseInt(event.target.value);
        if(abeyanceStatus === AbeyanceStatus.NotRequired.id) {
            abeyanceForm.abeyanceStartedDate.setValue("");
            abeyanceForm.extendRelatedToDueProcess.setValue("");
            abeyanceForm.relatedDueProcessId.setValue("");
            abeyanceForm.abeyanceExitDate.setValue("");
        }
        else if(abeyanceStatus === AbeyanceStatus.InAbeyance.id) {
            abeyanceForm.abeyanceExitDate.setValue("");
        }

        abeyanceForm.abeyanceStatus.setValue(abeyanceStatus);
    };

    const fetchFormData = () => {
        const result = convertFormInputObjectToObject(timelineForm);
        result.timelineResponseModels = timelineResponses;
        result.dateFiled = dateFiled;
        return result;
    };

    const isValidAbeyance = () => {
        clearInputFormErrorFields(abeyanceForm);

        const abeyanceStatus = parseInt(abeyanceForm.abeyanceStatus.value);
        if(abeyanceStatus !== AbeyanceStatus.NotRequired.id && !abeyanceForm.abeyanceStartedDate.value)
            abeyanceForm.abeyanceStartedDate.setError("Required");

        if (abeyanceStatus !== AbeyanceStatus.NotRequired.id && abeyanceForm.relatedDueProcessId.value && !abeyanceForm.extendRelatedToDueProcess.value)
            abeyanceForm.extendRelatedToDueProcess.setError("Required");

        if(abeyanceStatus === AbeyanceStatus.Resolved.id && !abeyanceForm.abeyanceExitDate.value)
            abeyanceForm.abeyanceExitDate.setError("Required");

        let isValid = inputFormIsValid(abeyanceForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const handleClickSave = (event) => {
        event.preventDefault();

        if(!isValidAbeyance())
            return;

        const form = fetchFormData();
        const aForm = convertFormInputObjectToObject(abeyanceForm);

        if(!confirmAboutChangingResolutionType(aForm))
            return;

        handleSaveTimeline(form, aForm, clearOutFieldsAfterSave);
    };

    const confirmAboutChangingResolutionType = (aForm) => {
        const resolutionType = getNewResolutionTypeBasedUponAbeyanceStatus(aForm.abeyanceStatus, complaint.resolutionType);
        if (!!complaint.resolutionType && resolutionType !== complaint.resolutionType && (complaint.actionModels.length > 0 || complaint.issueModels.length > 0))
            return confirm("Changing the Abeyance status will clear out any existing issues and actions.\n\nPress \'OK\' to continue or \'Cancel\' to return to the page.");

        return true;
    }

    const handleClickCancel = (event) => {
        handleSelectSection(event, StateComplaintSections.Timeline, true);
    };

    const resetDateFiledAfterExtensionRemoved = () => {
        clearOutFieldsAfterSave();
    };

    const handleRemoveExtensionClick = (dateToRemove) => {
        if (!confirm("Are you sure you want to remove this extension?"))
            return;

        const aForm = convertFormInputObjectToObject(abeyanceForm);

        if(!confirmAboutChangingResolutionType(aForm))
            return;

        timelineForm.removeExtension.setValue(true);
        timelineForm.extendedTimelineDate.setValue(dateToRemove);

        const form = fetchFormData();
        handleSaveTimeline(form, aForm, resetDateFiledAfterExtensionRemoved);
    };

    const buildTimelineTriggers = (stage, allTimelineResponses) => {
        let startDate = null;
        if (stage.value === TimelineStagesEnum.PreInvestigation)
            startDate = dateFiled;
        else {
            const previousStageTimelineResponses = allTimelineResponses.filter(f => f.stage === stage.value - 1);
            startDate = previousStageTimelineResponses[previousStageTimelineResponses.length - 1].dueDate;
        }
        const stageTimelineResponses = allTimelineResponses.filter(f => f.stage === stage.value);

        const maxDate = stageTimelineResponses[stageTimelineResponses.length - 1].dueDate;
        const totalDays = getDifferentBetweenDatesInDays(maxDate, startDate);
        const dateDifference = getDifferentBetweenDatesInDays(maxDate);
        const currentDay = dateDifference > 0 ? dateDifference : 0;
        return (
            <GridRow>
                <GridColumn medium={"4"} columnClass={"timeline-label"}>
                    {TimelineStages[stage.id].Label}
                </GridColumn>
                <GridColumn medium={"4"}>
                    <ProgressBar
                        currentStep={totalDays - currentDay > 0 ? totalDays - currentDay : 0}
                        fullTextToDisplay={`${currentDay} Days until ${outputShortDateFormat(maxDate)}`}
                        progressTextPosition={InsideBar}
                        totalSteps={totalDays}
                        wrapperClass={"timeline-progress"}
                    />
                </GridColumn>
                <GridColumn medium={"4"}>
                    <ProgressBar
                        currentStep={stageTimelineResponses.filter(f => f.dateCompleted).length}
                        progressTextPosition={InsideBar}
                        progressTextPrefix={`Completed: `}
                        totalSteps={stageTimelineResponses.length}
                        wrapperClass={"timeline-progress"}
                    />
                </GridColumn>
            </GridRow>);
    };

    const isStageOpen = (stage) => {
        return (stageOpen === stage.value);
    };

    const scrollToOpenStage = (stageId) => {
        scrollTimelineView(stageId);
    }

    const isCompletionDateDisabled = (timeline) => {
        return readOnly || !isAbleToEditTimelineCompletionDate(userDrTeamMemberRoles, timeline.roleResponsibleForCompletion);
    };

    useEffect(() => {
        if(isNullOrUndefined(complaint))
            return;

        abeyanceForm.abeyanceStartedDate.setValue(complaint.abeyanceStartedDate);
        abeyanceForm.extendRelatedToDueProcess.setValue(complaint.additionalResponsesFromIntake.extendRelatedToDueProcess);
        abeyanceForm.relatedDueProcessId.setValue(complaint.additionalResponsesFromIntake.relatedDueProcessId);
        abeyanceForm.abeyanceExitDate.setValue(complaint.abeyanceExitDate);
        const abeyanceStatus = getAbeyanceStatusBasedUponResolutionTypeAndAbeyanceStartDate(complaint.resolutionType, complaint.abeyanceExitDate);
        abeyanceForm.abeyanceStatus.setValue(abeyanceStatus);
    }, [])

    useEffect(() => {
        drApi.GetDueProcessCaseNumbers(complaint.dateWindowId, complaint.districtId)
            .then((result) => setDueProcessCaseNumbers(createDueProcessCaseNumberList(result)));
    }, [complaint.dateWindowId, complaint.districtId]);

    if (!timelineModel)
        return null;

    const extensionsTrigger = <CollapsibleTrigger text={`General Timeline Information`}/>;

    return (
        <section className={"timeline"} id={`timeline-view`}>
            <SectionHeader>
                <h2>Timeline</h2>
            </SectionHeader>

            <CollapsibleContainer
                trigger={extensionsTrigger}
                id={`extension-container`}
            >
                <h3>General</h3>
                <FormTable>
                    <DateTimePickerField
                        name="dateFiled"
                        label="Day 1"
                        showLabel
                        value={dateFiled}
                        disabled
                        medium={6}
                    />
                </FormTable>
                <h3>Abeyance Information</h3>
                <FormTable>
                    <SelectField
                        disabled={readOnly}
                        includeDefaultOption={false}
                        label={`Abeyance Status`}
                        medium={6}
                        name={`abeyanceStatus`}
                        options={AbeyanceStatusList}
                        showLabel
                        {...abeyanceForm.abeyanceStatus}
                        onChange={handleAbeyanceStatusChange}
                    />
                    <DateTimePickerField
                        disabled={readOnly || AbeyanceStatus.NotRequired.id.toString() === abeyanceForm.abeyanceStatus.value.toString()}
                        label="Date entered into Abeyance"
                        medium={6}
                        name="abeyanceStartedDate"
                        showLabel
                        {...abeyanceForm.abeyanceStartedDate}
                    />
                    <SelectField
                        disabled={readOnly || AbeyanceStatus.NotRequired.id.toString() === abeyanceForm.abeyanceStatus.value.toString()}
                        label={"Issue classification:"}
                        medium={6}
                        options={RadioButtonChoicesOfExtendRelatedToDueProcess}
                        showLabel
                        {...abeyanceForm.extendRelatedToDueProcess}
                    />
                    <SelectField
                        disabled={readOnly || AbeyanceStatus.NotRequired.id.toString() === abeyanceForm.abeyanceStatus.value.toString()}
                        label={"Due Process Hearing Request Case No:"}
                        medium={6}
                        options={dueProcessCaseNumbers}
                        showLabel
                        {...abeyanceForm.relatedDueProcessId}
                    />
                    <DateTimePickerField
                        disabled={readOnly || AbeyanceStatus.Resolved.id.toString() !== abeyanceForm.abeyanceStatus.value.toString()}
                        label="Date exited out of Abeyance"
                        medium={6}
                        name="abeyanceExitDate"
                        showLabel
                        {...abeyanceForm.abeyanceExitDate}
                    />
                </FormTable>
                {
                    (hasNonAbeyanceExtension || !readOnly) &&
                    <>
                        <h3>Extension Information</h3>
                        <FormTable>
                            {
                                hasNonAbeyanceExtension &&
                                timelineModel.complaintNonAbeyanceExtensions.map((extension, index) => {
                                    return (
                                        <FieldsetLayout legend={`Timeline Extended`}
                                                        key={extension.complaintExtensionId}>
                                            <div className={"removeExtensionContainer"}>
                                                {
                                                    !readOnly &&
                                                    <span className={ActionButton.position.RIGHT}>
                                            <Button
                                                label={"Remove Extension"}
                                                name={"btnRemoveExtension"}
                                                buttonType={ButtonTypes.REMOVE}
                                                showLabel={false}
                                                onClick={() => handleRemoveExtensionClick(extension.extendedOrderSignedDate)}
                                            />
                                        </span>
                                                }
                                                <DateTimePickerField
                                                    name={`TimelineExtendedDate`}
                                                    label={`Extended Order Signed Date #${index + 1}`}
                                                    showLabel
                                                    value={extension.extendedOrderSignedDate}
                                                    disabled
                                                    medium={6}
                                                />
                                            </div>
                                            <DisplayField
                                                name={`TimelineExtendedReason`}
                                                label={`Reason`}
                                                columnsMedium={6}
                                                showLabel
                                            >
                                                <p>{extension.reasonForExtension}</p>
                                            </DisplayField>
                                        </FieldsetLayout>);
                                })
                            }
                            {
                                !readOnly &&
                                <>
                                    <DateTimePickerField
                                        name="extendedTimelineDate"
                                        label="If extending the timeline, provide the new extended Order Signed Date"
                                        showLabel
                                        {...timelineForm.extendedTimelineDate}
                                        medium={6}
                                    />
                                    {
                                        !!timelineForm.extendedTimelineDate.value &&
                                        <TextField
                                            name="reasonForExtension"
                                            label="Reason for Extension/Abeyance"
                                            showLabel
                                            {...timelineForm.reasonForExtension}
                                            columnsMedium={6}
                                        />
                                    }
                                </>
                            }
                        </FormTable>
                    </>
                }
            </CollapsibleContainer>
            {
                TimelineStagesList.map((stage, stageIndex) => {
                    const stageTimelineResponses = timelineResponses.filter(f => f.stage === stage.value);
                    return (
                        <CollapsibleContainer
                            trigger={buildTimelineTriggers(stage, timelineResponses)}
                            open={isStageOpen(stage)}
                            key={stageIndex}
                            id={`stage-container-${stageIndex}`}
                            onOpen={() => scrollToOpenStage(`stage-container-${stageIndex}`)}
                        >
                            {
                                stageTimelineResponses.map((timeline, index) => {
                                    return (
                                        <FormTable key={index}>
                                            <DrStateComplaintTimelineRequirement
                                                handleChangeCompletionDate={handleChangeCompletionDate}
                                                handleChangeMediationResponse={handleChangeMediationResponse}
                                                isAbleToEditTimelineCompletionDate={isAbleToEditTimelineCompletionDate(userDrTeamMemberRoles, timeline.roleResponsibleForCompletion)}
                                                readOnly={isCompletionDateDisabled(timeline)}
                                                timeline={timeline}
                                            />
                                        </FormTable>
                                    );
                                })
                            }
                        </CollapsibleContainer>
                    );
                })
            }
            {
                !readOnly &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button name="btnSave"
                            label={`Save`}
                            buttonType={ButtonTypes.SAVE}
                            onClick={handleClickSave}
                    />
                    <Button name="btnCancel"
                            label="Cancel and Reload"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleClickCancel}/>

                    <DrStateComplaintNextSteps handleSelectSection={handleSelectSection} complaint={complaint}/>
                </ButtonBar>
            }
        </section>
    );
};

DrStateComplaintTimelineView.propTypes = {
    complaint: PropTypes.object,
    dateFiled: PropTypes.string.isRequired,
    handleChangeCompletionDate: PropTypes.func.isRequired,
    handleChangeMediationResponse: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveTimeline: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    hasNonAbeyanceExtension: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    scrollTimelineView: PropTypes.func.isRequired,
    stageOpen: PropTypes.number.isRequired,
    timelineModel: PropTypes.object,
    timelineResponses: PropTypes.array.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrStateComplaintTimelineView;