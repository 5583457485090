import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";

export function validateTotalParticipants(totalParticipants) {
    const updatedTotalParticipants = [...totalParticipants];
    let hasErrors = false;
    for (let district of updatedTotalParticipants) {
        if(isTrimmedStringEmpty(district.totalParticipants.toString())) {
            district.error = "Required.";
            hasErrors = true;
        }
        else if(district.totalParticipants < 1) {
            hasErrors = true;
        }
    }

    return {updatedTotalParticipants, hasErrors};
}