import PropTypes from "prop-types";
import React from "react";
import * as config from "../../constants/config";
import HtmlDisplayInline from "../htmlDisplay/HtmlDisplayInline";

class Footer extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<footer>
				<p>
					This system is funded by BEESS, Florida Department of Education. It is maintained by the Personnel
					Development Support Project at the Florida Center for Interactive Media at Florida State University.
					<br/>
					Having trouble? We are here to help. For support, <HtmlDisplayInline html={config.supportDetails(this.props.currentContext)} />.
				</p>
			</footer>
		);
	}
}

Footer.propTypes = {
	currentContext: PropTypes.array
};

export default Footer;