import * as contexts from "./contexts";

export const commonStyles = {
    highlight:{
        MISSING: `missing-data-highlight`
    }
};

export const themeConstants = {
    USERS: contexts.USERS,
    SURVEYS: contexts.SURVEYS,
    SPP: "spp",
    STRATEGIC_PLAN: contexts.STRATEGIC_PLAN,
    ADMIN: contexts.ADMIN,
    MONITORING_PERFORMANCE: contexts.MONITORING_PERFORMANCE,
    MONITORING_COMPLIANCE: contexts.MONITORING_COMPLIANCE,
    STATE_COMPLAINTS: "dispute",
    DUE_PROCESS: "dispute",
    MEDIATIONS: "dispute",
    BPIE: "bpie",
    GMS: "gms",
    LOGIN: "login",
    PRINT: "print"
};